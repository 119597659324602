import { call, put } from "redux-saga/effects";
import { getStockRequest } from "../../requests/stockRequest/StockRequest";
import { setStock } from "../../../ducks/stockSlice";
export function* handleGetStock(action) {
    console.log(action);
    try {
      
      const { data } = yield call(getStockRequest, action);
      
        yield put(setStock(data));
     
      console.log('no working');
      console.log(data);
    } catch (error) {
      const {
        response: { data },
      } = error;
  
      console.log(data);
    //   yield put(setLoginError(data));
    }
  }