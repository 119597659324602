import { createSlice } from "@reduxjs/toolkit";

const templateSlice = createSlice({
  name: "template",
  initialState: {
    templateList: {},
  },
  reducers: {
    getTemplate() {},
    setTemplate(state, action) {
      const templateList = action.payload;
      return { ...state, templateList };
    },
  },
});

export const { getTemplate, setTemplate } = templateSlice.actions;

export default templateSlice.reducer;
