import axios from "axios";
import { REACT_APP_BACKEND_URL } from "../config/config";
import { ADMIN_BACKEND_URL } from "../config/config";

const token = localStorage.getItem('adminToken')

export const addNewCompany = async (formDataObj) => {
    try {
        const data = await axios.post(
            `${ADMIN_BACKEND_URL}/admin/company-profile`,
            formDataObj
        );
        return data;
    } catch (error) {
        console.log(error);
    }
};

export const getProfile = async () => {
    try {
        const data = await axios.get(
            `${ADMIN_BACKEND_URL}/admin/company-profile`,
        );
        return data;
    } catch (error) {
        console.log(error);
    }
};

export const editProfile = async (formDataObj, id) => {
    const encodedId = encodeURIComponent(id);
    const folderName = "profile";
    console.log(id,'formData id');
    try {
        const data = await axios.post(
            `${ADMIN_BACKEND_URL}/admin/edit/company-profile`, formDataObj
            );
            return data;
    } catch (error) {
        console.log(error);

    }
}