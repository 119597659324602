import { createSlice } from "@reduxjs/toolkit";

const user = localStorage.getItem('adminToken')
let islog = false
if (user){
  islog=true
}

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    isLoggedIn: islog,
  },
  reducers: {
    getAdmin() {},
    setAdmin: (state, action) => {
      state.isLoggedIn = true;
    },
    setAdminLogOut: (state, action) => {
      state.isLoggedIn = false;
    },
  },
});

export const { getAdmin, setAdmin, setAdminLogOut } = adminSlice.actions;

export default adminSlice.reducer;
