import { createSlice } from "@reduxjs/toolkit";

const profileSlice = createSlice({
  name: "profile",
  initialState: {},
  profile:{},
  reducers: {
    getProfile() {},
    setProfile(state, action) {
      const profile = action.payload;
      console.log(profile);
      return { ...state, profile };
    },
    setCompanyProfile(state, action) {
      const profile = action.payload;
      console.log(profile);
      return { ...state, profile:profile };
    },
  },
});

export const { getProfile, setProfile,setCompanyProfile } = profileSlice.actions;

export default profileSlice.reducer;
