import React from 'react';

// ==============================|| LOADER ||============================== //
const Loader = () => (
  <div className="tw-fixed tw-top-0 tw-left-0 tw-z-50 tw-w-full">
    <div className="tw-h-1">
      <div className="tw-h-full tw-bg-sub"></div>
    </div>
  </div>
);

export default Loader;
