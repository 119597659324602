import { createSlice } from "@reduxjs/toolkit";
const routeSlice = createSlice({
  name: "route",
  initialState: {
    mainroute: "",
    subroute: "",
    filteredMainRoute:'',
    filteredSubRoute:''

  },

  reducers: {
    setMainRoute(state, action) {
      const mainroute = action.payload;
      return { ...state, mainroute: mainroute };
    },
    setsubroute(state, action) {
      const subroute = action.payload;
      return { ...state, subroute: subroute };
    },
    setSubRoute(state, action) {
      const subroute = action.payload;
      return { ...state, subroute: subroute };
    },
    setFilteredMainRoute(state, action) {
      const subroute = action.payload;
      return { ...state, filteredMainRoute: subroute };
    },
    setFilteredSubRoute(state, action) {
      const subroute = action.payload;
      return { ...state, filteredSubRoute: subroute };
    },
    resetMainRoute(state, action) {
      return null; // Reset the state to initial state (null)
    },

    addMainRoute() {},
    addSubRoute() {},

    getMainRoute() {},
    getSubRoute() {},
    endMainRoute() {},
  },
});

export const {
  setMainRoute,
  addMainRoute,
  getMainRoute,
  endMainRoute,
  resetMainRoute,
  addSubRoute,
  getSubRoute,
  setSubRoute,
  setsubroute, 
  setFilteredMainRoute,
  setFilteredSubRoute
} = routeSlice.actions;

export default routeSlice.reducer;
