import axios from "axios";
import { ADMIN_BACKEND_URL } from "../../../../config/config";

  export function getOrdersRequest({payload}) {
    const fromDate = payload?.fromDate
    const toDate = payload?.toDate
    console.log(fromDate,'aluva');
    console.log(toDate,'perumbavoor');

    return axios.request({
      headers: {
        "Content-type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
      method: "get",
      url: `${ADMIN_BACKEND_URL}/admin/orders?from=${fromDate}&to=${toDate}`,
    });
  }
  export function getAllOrdersRequest(fromDate,toDate,search,limit,skip) {
    return axios.request({
      headers: {
        "Content-type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
      method: "get",
      url: `${ADMIN_BACKEND_URL}/admin/all-orders?from=${fromDate}&to=${toDate}&search=${search}&page=${skip}&pageSize=${limit}`,
    });
  }