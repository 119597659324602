import { call, put } from "redux-saga/effects";
import { setSaleslist } from "../../../ducks/reportSlice";
import { getSalesListRequest } from "../../requests/reportRequest/ReportRequest";
  export function* handleGetSalesList(action) {
    console.log(action);
    try {
      const { data } = yield call(getSalesListRequest, action);
      if (data) {
        yield put(setSaleslist(data));
      }
      console.log("no working");
      console.log(data);
    } catch (error) {
      const {
        response: { data },
      } = error;
  
      console.log(data);
      //   yield put(setLoginError(data));
    }
  }