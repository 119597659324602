export const REACT_APP_BACKEND_URL = "http://localhost:8000/api";

export const ADMIN_BACKEND_URL = process.env.NODE_ENV === 'production'
  ? '/api'
  : 'http://localhost:7000/api';
  

// export const REACT_APP_BACKEND_URL = "https://backend.kxence.store/api";

// testing line for building
