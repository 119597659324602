export const industries = [
    { industry: "automation" },
    { industry: "vehicle" },
    { industry: "sales" },
];

export const dateFormats = [
    { value: "mm/dd/yyyy", label: "MM/DD/YYYY", example: "[03/13/2022]" },
    { value: "dd/mm/yyyy", label: "DD/MM/YYYY", example: "[13/03/2022]" },
    { value: "yyyy/mm/dd", label: "YYYY-MM-DD", example: "[2022-03-13]" },
    { value: "dd/mmm/yyyy", label: "DD-MMM-YYYY", example: "[13-Mar-2022]" },
    { value: "mmm/dd/yyyy", label: "Month D, YYYY",example: "[March 13, 2022]"},
];

export const financialYears = [
    { label: "January - December", value: "jan-dec" },
    { label: "February - January", value: "feb-jan" },
    { label: "March - February", value: "mar-feb" },
    { label: "April - March", value: "apr-mar" },
    { label: "May - April", value: "may-apr" },
    { label: "June - May", value: "jun-may" },
    { label: "July - June", value: "jul-jun" },
    { label: "August - July", value: "aug-jul" },
    { label: "September - August", value: "sep-aug" },
    { label: "October - September", value: "oct-sep" },
    { label: "November - October", value: "nov-oct" },
    { label: "December - November", value: "dec-nov" },
];

export const taxPreferences = [{ value: "Indian" }, { value: "Pakistan" }];


export const paymentTermses = [
    { value: "Due on receipt" },
    { value: "Due end of the month" },
    { value: "Due next Month" },
];

export const stockStatus = [{ value: "In Stock" }, { value: "Out Of Stock" }];

export  const journalEntryStatus = [
    {
        value: "Published",
    },
    {
        value: "Draft",
    },
];



export const chartOfAccountData = [
    {
      _id: 1,
      bankName: "Other Charges",
      description: "Miscellaneous charges like adjustments made to the invoice can be recorded in this account.",
      accountType: "Income",
      accountNumber: 0,
      isMileage: false,
      currency: "INR"
    },
    {
      __id: 2,
      bankName: "Lodging",
      description: "Any expense related to putting up at motels etc while on business travel can be entered here.",
      accountType: "Expense",
      accountNumber: 0,
      isMileage: false,
      currency: "INR"
    },
    {
      _id: 3,
      bankName: "Undeposited Funds",
      description: "Record funds received by your company yet to be deposited in a bank as undeposited funds and group them as a current asset in your balance sheet.",
      accountType: "Cash",
      accountNumber: 0,
      isMileage: false,
      currency: "INR"
    },
    {
      _id: 4,
      bankName: "Petty Cash",
      description: "It is a small amount of cash that is used to pay your minor or casual expenses rather than writing a check.",
      accountType: "Cash",
      accountNumber: 0,
      isMileage: false,
      currency: "INR"
    },
    {
      _id: 5,
      bankName: "Accounts Receivable",
      description: "The money that customers owe you becomes the accounts receivable. A good example of this is a payment expected from an invoice sent to your customer.",
      accountType: "Accounts Receivable",
      accountNumber: 0,
      isMileage: false,
      currency: "INR"
    },
    {
      _id: 6,
      bankName: "Furniture and Equipment",
      description: "Purchases of furniture and equipment for your office that can be used for a long period of time usually exceeding one year can be tracked with this account.",
      accountType: "Fixed Asset",
      accountNumber: 0,
      isMileage: false,
      currency: "INR"
    },
    {
      _id: 7,
      bankName: "Advance Tax",
      description: "Any tax which is pa_id in advance is recorded into the advance tax account. This advance tax payment could be a quarterly, half yearly or yearly payment.",
      accountType: "Other Current Asset",
      accountNumber: 0,
      isMileage: false,
      currency: "INR"
    },
    {
      _id: 8,
      bankName: "Accounts Payable",
      description: "This is an account of all the money which you owe to others like a pending bill payment to a vendor, etc.",
      accountType: "Accounts Payable",
      accountNumber: 0,
      isMileage: false,
      currency: "INR"
    },
    {
      _id: 9,
      bankName: "Tax Payable",
      description: "The amount of money which you owe to your tax authority is recorded under the tax payable account. This amount is a sum of your outstanding in taxes and the tax charged on sales.",
      accountType: "Other Current Liability",
      accountNumber: 0,
      isMileage: false,
      currency: "INR"
    },
    {
      _id: 10,
      bankName: "Retained Earnings",
      description: "The earnings of your company which are not distributed among the shareholders is accounted as retained earnings.",
      accountType: "Equity",
      accountNumber: 0,
      isMileage: false,
      currency: "INR"
    }
]
  
export const AccountType= [
  { accountType: 'Liablity' },
  { accountType: 'Assets' },
  { accountType: 'Sales TO' },
  { accountType: 'Discount' },
  { accountType: 'Purchases' },
  { accountType: 'Operating Exp' },
  { accountType: 'Staff' },
  { accountType: 'Depreciation' },
  { accountType: 'Cash' },
  { accountType: 'Bank' },
  { accountType: 'Credit Card' },

]
  
export const AccountHead=[
  { accountHead: 'Capital' },
  { accountHead: 'Current Liability' },
  { accountHead: 'Fixed Assets' },
  { accountHead: 'Current Assets' },
  { accountHead: 'Income' },
  { accountHead: 'Expenses' }
]
  
export const Parent=[ { parent: 'BS' }, { parent: 'PL' }, { parent: 'PL/BS' } ]

export const countries = [
  { name: "Afghanistan" },
  { name: "Albania" },
  { name: "Algeria" },
  { name: "Andorra" },
  { name: "Angola" },
  { name: "Antigua and Barbuda" },
  { name: "Argentina" },
  { name: "Armenia" },
  { name: "Australia" },
  { name: "Austria" },
  { name: "Azerbaijan" },
  { name: "Bahamas" },
  { name: "Bahrain" },
  { name: "Bangladesh" },
  { name: "Barbados" },
  { name: "Belarus" },
  { name: "Belgium" },
  { name: "Belize" },
  { name: "Benin" },
  { name: "Bhutan" },
  { name: "Bolivia" },
  { name: "Bosnia and Herzegovina" },
  { name: "Botswana" },
  { name: "Brazil" },
  { name: "Brunei" },
  { name: "Bulgaria" },
  { name: "Burkina Faso" },
  { name: "Burundi" },
  { name: "Côte d'Ivoire" },
  { name: "Cabo Verde" },
  { name: "Cambodia" },
  { name: "Cameroon" },
  { name: "Canada" },
  { name: "Central African Republic" },
  { name: "Chad" },
  { name: "Chile" },
  { name: "China" },
  { name: "Colombia" },
  { name: "Comoros" },
  { name: "Congo (Congo-Brazzaville)" },
  { name: "Costa Rica" },
  { name: "Croatia" },
  { name: "Cuba" },
  { name: "Cyprus" },
  { name: "Czechia" },
  { name: "Democratic Republic of the Congo (Congo-Kinshasa)" },
  { name: "Denmark" },
  { name: "Djibouti" },
  { name: "Dominica" },
  { name: "Dominican Republic" },
  { name: "Ecuador" },
  { name: "Egypt" },
  { name: "El Salvador" },
  { name: "Equatorial Guinea" },
  { name: "Eritrea" },
  { name: "Estonia" },
  { name: "Eswatini" },
  { name: "Ethiopia" },
  { name: "Fiji" },
  { name: "Finland" },
  { name: "France" },
  { name: "Gabon" },
  { name: "Gambia" },
  { name: "Georgia" },
  { name: "Germany" },
  { name: "Ghana" },
  { name: "Greece" },
  { name: "Grenada" },
  { name: "Guatemala" },
  { name: "Guinea" },
  { name: "Guinea-Bissau" },
  { name: "Guyana" },
  { name: "Haiti" },
  { name: "Holy See" },
  { name: "Honduras" },
  { name: "Hungary" },
  { name: "Iceland" },
  { name: "India" }, // This is the country you originally mentioned
  { name: "Indonesia" },
  { name: "Iran" },
  { name: "Iraq" },
  { name: "Ireland" },
  { name: "Israel" },
  { name: "Italy" },
  { name: "Jamaica" },
  { name: "Japan" },
  { name: "Jordan" },
  { name: "Kazakhstan" },
  { name: "Kenya" },
  { name: "Kiribati" },
  { name: "Kuwait" },
  { name: "Kyrgyzstan" },
  { name: "Laos" },
  { name: "Latvia" },
  { name: "Lebanon" },
  { name: "Lesotho" },
  { name: "Liberia" },
  { name: "Libya" },
  { name: "Liechtenstein" },
  { name: "Lithuania" },
  { name: "Luxembourg" },
  { name: "Madagascar" },
  { name: "Malawi" },
  { name: "Malaysia" },
  { name: "Maldives" },
  { name: "Mali" },
  { name: "Malta" },
  { name: "Marshall Islands" },
  { name: "Mauritania" },
  { name: "Mauritius" },
  { name: "Mexico" },
  { name: "Micronesia" },
  { name: "Moldova" },
  { name: "Monaco" },
  { name: "Mongolia" },
  { name: "Montenegro" },
  { name: "Morocco" },
  { name: "Mozambique" },
  { name: "Myanmar (formerly Burma)" },
  { name: "Namibia" },
  { name: "Nauru" },
  { name: "Nepal" },
  { name: "Netherlands" },
  { name: "New Zealand" },
  { name: "Nicaragua" },
  { name: "Niger" },
  { name: "Nigeria" },
  { name: "North Macedonia (formerly Macedonia)" },
  { name: "Norway" },
  { name: "Oman" },
  { name: "Pakistan" },
  { name: "Palau" },
  { name: "Palestine State" },
  { name: "Panama" },
  { name: "Papua New Guinea" },
  { name: "Paraguay" },
  { name: "Peru" },
  { name: "Philippines" },
  { name: "Poland" },
  { name: "Portugal" },
  { name: "Qatar" },
  { name: "Romania" },
  { name: "Russia" },
  { name: "Rwanda" },
  { name: "Saint Kitts and Nevis" },
  { name: "Saint Lucia" },
  { name: "Saint Vincent and the Grenadines" },
  { name: "Samoa" },
  { name: "San Marino" },
  { name: "Sao Tome and Principe" },
  { name: "Saudi Arabia" },
  { name: "Senegal" },
  { name: "Serbia" },
  { name: "Seychelles" },
  { name: "Sierra Leone" },
  { name: "Singapore" },
  { name: "Slovakia" },
  { name: "Slovenia" },
  { name: "Solomon Islands" },
  { name: "Somalia" },
  { name: "South Africa" },
  { name: "South Korea" },
  { name: "South Sudan" },
  { name: "Spain" },
  { name: "Sri Lanka" },
  { name: "Sudan" },
  { name: "Suriname" },
  { name: "Sweden" },
  { name: "Switzerland" },
  { name: "Syria" },
  { name: "Tajikistan" },
  { name: "Tanzania" },
  { name: "Thailand" },
  { name: "Timor-Leste" },
  { name: "Togo" },
  { name: "Tonga" },
  { name: "Trinidad and Tobago" },
  { name: "Tunisia" },
  { name: "Turkey" },
  { name: "Turkmenistan" },
  { name: "Tuvalu" },
  { name: "Uganda" },
  { name: "Ukraine" },
  { name: "United Arab Emirates" },
  { name: "United Kingdom" },
  { name: "United States of America" },
  { name: "Uruguay" },
  { name: "Uzbekistan" },
  { name: "Vanuatu" },
  { name: "Venezuela" },
  { name: "Vietnam" },
  { name: "Yemen" },
  { name: "Zambia" },
  { name: "Zimbabwe" }
];

 
