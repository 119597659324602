const currencies = [
    {
      country: 'United Arab Emirates',
      currencyCode: 'AED',
      currencyName: 'United Arab Emirates Dirham',
      symbol: 'د.إ'
    },
    {
      country: 'United States',
      currencyCode: 'USD',
      currencyName: 'United States Dollar',
      symbol: '$'
    },
    {
      country: 'India',
      currencyCode: 'INR',
      currencyName: 'Indian Rupee',
      symbol: 'AED'
    },
    {
      country: 'Canada',
      currencyCode: 'CAD',
      currencyName: 'Canadian Dollar',
      symbol: 'C$'
    },
    {
      country: 'United Kingdom',
      currencyCode: 'GBP',
      currencyName: 'British Pound Sterling',
      symbol: '£'
    },
    {
      country: 'Australia',
      currencyCode: 'AUD',
      currencyName: 'Australian Dollar',
      symbol: 'A$'
    },
    {
      country: 'Japan',
      currencyCode: 'JPY',
      currencyName: 'Japanese Yen',
      symbol: '¥'
    }
    // Add more countries and currencies as needed
  ];
  
export default currencies