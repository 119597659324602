import { createSlice } from "@reduxjs/toolkit";

const creditNoteSlice = createSlice({
  name: "creditNote",
  initialState: {
    creditNotesList: [],
    lastCnt: [],
  },
  reducers: {
    getCreditNote() {},
    getAddNewCreditNote() {},
    setCreditNote(state, action) {
      const creditNotesList = action.payload;
      return { ...state, creditNotesList };
    },
    setLastCnt(state, action) {
      const lastCnt = action.payload;
      return { ...state, lastCnt };
    },
  },
});

export const { getAddNewCreditNote, getCreditNote, setCreditNote, setLastCnt } =
  creditNoteSlice.actions;

export default creditNoteSlice.reducer;
