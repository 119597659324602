import { createSlice } from "@reduxjs/toolkit";
const stockSlice = createSlice({
  name: "stock",
  initialState: {
    stocks:[]
  },

  reducers: {
    setStock(state, action) {
      const stock = action.payload;
      return { ...state,stocks:stock };
    },
    resetStock(state, action) {
      return null; // Reset the state to initial state (null)
    },
   
    addStock() {},
    getStock(){},
    endStock(){}
  },
});

export const { setStock ,addStock,getStock,endStock,resetStock} = stockSlice.actions;

export default  stockSlice.reducer;
