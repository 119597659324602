import { fetchAllCustomers } from "../../../../services/customerServices";
import axios from "axios";
import { ADMIN_BACKEND_URL } from "../../../../config/config";
export async function requestGetAllCustomer () {
  const { data } = await fetchAllCustomers()
  return data;
}

export function getCustomersRequest() {
  return axios.request({
    headers: {
      "Content-type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
    method: "get",
    url: `${ADMIN_BACKEND_URL}/admin/customers`,
  });
}

export function getACustomerDetailRequest({payload}) {
  console.log('working detail request');

  console.log(payload);
  const id =payload.id
  console.log(id);
  return axios.request({
    headers: {
      "Content-type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
    method: "get",
    url: `${ADMIN_BACKEND_URL}/admin/customer/detail/${id}`,
  });
}