import { takeLatest, fork, all } from "redux-saga/effects";
import {
  getCustomer,
  getCustomers,
  getSingleCustomerDetail,
} from "../ducks/customerSlice";
import { getEstimate, getAddNewEstimate } from "../ducks/estimateSlice";
import { getSalesOrder } from "../ducks/salesOrderSlice";
import {
  handleGetAllCustomer,
  handleGetCustomers,
  handleGetACustomerDetail,
} from "./handlers/customerHandler/getAllCustomerHandler";
import { handleGetAllEstimate } from "./handlers/estimateHandler/getAllEstimateHandler";
import { handleGetAddNewEstimate } from "./handlers/estimateHandler/getAddNewEstimateHandler";
import { handleGetAllItem } from "./handlers/itemHandler/getAllItemHandler";
import { getItem } from "../ducks/itemSlice";
import { handleGetAllSalesOrder } from "./handlers/salesOrderHandler/getAllSalesOrderHandler";
import { getAdmin } from "../ducks/adminSlice";
import { handleAdmin } from "./handlers/adminHandler/getAdminHandler";
import {
  handleAddVehicle,
  handleGetVehicle,
} from "./handlers/vehicleHandler/VehicleHandler";
import { addVehicle, getVehicle } from "../ducks/vehicleSlice";
import {
  handleAddMainRoute,
  handleAddSubRoute,
} from "./handlers/routeHandler/RouteHandler";
import { addMainRoute, addSubRoute } from "../ducks/routeSlice";
import { addStaff, getStaff, getUsers } from "../ducks/staffSlice";
import {
  handleAddStaff,
  handleGetStaff,
  handleGetUsers,
} from "./handlers/staffHandler/StaffHandler";
import { getMainRoute, getSubRoute } from "../ducks/routeSlice";
import {
  handleGetMainRoute,
  handleGetSubRoute,
} from "./handlers/routeHandler/RouteHandler";
import { addOrders, getOrders } from "../ducks/orderslice";
import { handleGetOrders } from "./handlers/orderHnadler/OrderHandler";
import { handleGetSalesList } from "./handlers/reportHandler/ReportHandler";
import { getSalesList } from "../ducks/reportSlice";
import { getStock } from "../ducks/stockSlice";
import { handleGetStock } from "./handlers/stockHandler/StockHandler";

export function* watchLoginAdmin() {
  yield takeLatest(getAdmin, handleAdmin);
}

export function* watchGetAllCustomer() {
  yield takeLatest(getCustomer, handleGetAllCustomer);
}

export function* watchGetAllItem() {
  yield takeLatest(getItem, handleGetAllItem);
}

export function* watchGetAddNewEstimate() {
  yield takeLatest(getAddNewEstimate, handleGetAddNewEstimate);
}

export function* watchGetAllEstimate() {
  yield takeLatest(getEstimate, handleGetAllEstimate);
}

export function* watchGetAllSalesOrder() {
  yield takeLatest(getSalesOrder, handleGetAllSalesOrder);
}
export function* watchAddVehicle() {
  yield takeLatest(addVehicle, handleAddVehicle);
}
export function* watchAddMainRoute() {
  yield takeLatest(addMainRoute, handleAddMainRoute);
}

export function* watchAddSubRoute() {
  yield takeLatest(addSubRoute, handleAddSubRoute);
}
export function* watchGetStaff() {
  yield takeLatest(getStaff, handleGetStaff);
}
export function* watchGetUsers() {
  yield takeLatest(getUsers, handleGetUsers);
}
export function* watchGetMainRoute() {
  yield takeLatest(getMainRoute, handleGetMainRoute);
}
export function* watchGetSubRoute() {
  yield takeLatest(getSubRoute, handleGetSubRoute);
}

export function* watchGetVehicle() {
  yield takeLatest(getVehicle, handleGetVehicle);
}
export function* watchGetOrders() {
  yield takeLatest(getOrders, handleGetOrders);
}
export function* watchGetSalesList() {
  yield takeLatest(getSalesList, handleGetSalesList);
}
export function* watchGetStock() {
  yield takeLatest(getStock, handleGetStock);
}
export function* watchGetCustomers() {
  yield takeLatest(getCustomers, handleGetCustomers);
}
export function* watchGetACustomerDetail() {
  yield takeLatest(getSingleCustomerDetail, handleGetACustomerDetail);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllCustomer),
    fork(watchGetAllItem),
    fork(watchGetAddNewEstimate),
    fork(watchGetAllEstimate),
    fork(watchGetAllSalesOrder),
    fork(watchAddVehicle),
    fork(watchAddMainRoute),
    fork(watchAddSubRoute),
    fork(watchGetStaff),
    fork(watchGetMainRoute),
    fork(watchGetVehicle),
    fork(watchGetUsers),
    fork(watchGetOrders),
    fork(watchGetSalesList),
    fork(watchGetSubRoute),
    fork(watchGetStock),
    fork(watchGetCustomers),
    fork(watchGetACustomerDetail)
  ]);
}
