import { call, put } from "redux-saga/effects";
import { getOrdersRequest } from "../../requests/orderRequest/OrderRequest";
import { setOrders } from "../../../ducks/orderslice";
  export function* handleGetOrders(action) {
    console.log(action);
    try {
      const { data } = yield call(getOrdersRequest, action);
      if (data) {
        yield put(setOrders(data));
      }
      console.log("no working");
      console.log(data);
    } catch (error) {
      const {
        response: { data },
      } = error;
  
      console.log(data);
      //   yield put(setLoginError(data));
    }
  }