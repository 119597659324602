import { call, put } from "redux-saga/effects";
import { getStaffRequest,getUsersRequest } from "../../requests/staffRequest/StaffRequest";
import { setStaff,setUsers } from "../../../ducks/staffSlice";
export function* handleGetStaff(action) {
    console.log(action);
    try {
      
      const { data } = yield call(getStaffRequest, action);
      if (data) {
        yield put(setStaff(data));
      }
      console.log('no working');
      console.log(data);
    } catch (error) {
      const {
        response: { data },
      } = error;
  
      console.log(data);
    //   yield put(setLoginError(data));
    }
  }
  export function* handleGetUsers(action) {
    console.log(action);
    try {
      
      const { data } = yield call(getUsersRequest, action);
      if (data) {
        yield put(setUsers(data));
      }
      console.log('no working');
      console.log(data);
    } catch (error) {
      const {
        response: { data },
      } = error;
  
      console.log(data);
    //   yield put(setLoginError(data));
    }
  }
  