import { createSlice } from "@reduxjs/toolkit";
const staffSlice = createSlice({
  name: "staff",
  initialState: {
    staff: "",
    users: "",
    filteredStaff:''
  },

  reducers: {
    setStaff(state, action) {
      const staff = action.payload;
      return { ...state, staff: staff };
    },
    setFilteredStaff(state, action) {
      const staff = action.payload;
      return { ...state, filteredStaff: staff };
    },
    setUsers(state, action) {
      const users = action.payload;
      return { ...state, users: users };
    },
    resetStaff(state, action) {
      return null; // Reset the state to initial state (null)
    },

    addStaff() {},
    getStaff() {},
    getUsers() {},
    endStaff() {},
  },
});

export const {
  setStaff,
  addStaff,
  getStaff,
  endStaff,
  resetStaff,
  setUsers,
  getUsers,
  setFilteredStaff
} = staffSlice.actions;

export default staffSlice.reducer;
