import { createSlice } from "@reduxjs/toolkit";

const vendorCreditSlice = createSlice({
  name: "vendorCredit",
  initialState: {
    vendorCreditsList: [],
    lastVct: [],
  },
  reducers: {
    getVendorCredit() {},
    getAddNewVendorCredit() {},
    setVendorCredit(state, action) {
      const vendorCreditsList = action.payload;
      return { ...state, vendorCreditsList };
    },
    setLastVct(state, action) {
      const lastVct = action.payload;
      return { ...state, lastVct };
    },
  },
});

export const {
  getAddNewVendorCredit,
  getVendorCredit,
  setLastVct,
  setVendorCredit,
} = vendorCreditSlice.actions;

export default vendorCreditSlice.reducer;
