import { createSlice } from "@reduxjs/toolkit";

const customerSlice = createSlice({
  name: "customer",
  initialState: {
    customersList: [],
    customers: "",
    singleCustomerDetail: {lastPurchase:[]},
    filteredCustomers:''
  },
  reducers: {
    getCustomer() {},
    getCustomers() {},
    getSingleCustomerDetail() {},

    setCustomer(state, action) {
      const customersList = action.payload;
      return { ...state, customersList };
    },
    setCustomers(state, action) {
      const customersList = action.payload;
      return { ...state, customers: customersList };
    },
    setFilteredCustomers(state, action) {
      const customersList = action.payload;
      return { ...state, filteredCustomers: customersList };
    },
    setSingleCustomers(state, action) {
      return { ...state, singleCustomerDetail: action.payload };
    },
  },
});

export const {
  getCustomer,
  setCustomer,
  getCustomers,
  setCustomers,
  getSingleCustomerDetail,
  setSingleCustomers,
  setFilteredCustomers
} = customerSlice.actions;

export default customerSlice.reducer;
