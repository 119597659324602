import axios from "axios";
import { REACT_APP_BACKEND_URL } from "../config/config";
import { ADMIN_BACKEND_URL } from "../config/config";

export const addNewItem = async (formDataObj, itemId) => {
    try {
        const data = await axios.post(`${ADMIN_BACKEND_URL}/admin/add-item`, formDataObj)
        return data;

    } catch (error) {
        console.log(error);
        throw error

    }
}

export const editAllItem = async (formDataObj,id) => {
    try {
        const data = await axios.post(`${ADMIN_BACKEND_URL}/admin/edit-item/${id}`, formDataObj)
        return data
    } catch (error) {
        console.log(error);
        throw error

    }
}


export const fetchAllItems = async () => {
    try {
        const {data} = await axios.get(`${ADMIN_BACKEND_URL}/admin/get-item`)
        return data;

    } catch (error) {
        console.log(error);

    }
}
export const fetchAInventoryTransaction = async (id) => {
    try {
        const {data} = await axios.get(`${ADMIN_BACKEND_URL}/inventory/item-ledger/${id}`)
        return data;

    } catch (error) {
        console.log(error);

    }
}

export const fetchOneItem = async (itemId) => {
    try {
        const data = await axios.get(`${ADMIN_BACKEND_URL}/admin/get-item/${itemId}`)
        return data;

    } catch (error) {
        console.log(error);

    }
}
export const fetchPurchaseTowarehouseStock = async (itemId) => {
    try {
        const data = await axios.get(`${ADMIN_BACKEND_URL}/admin/pur-ware-stock`)
        return data;

    } catch (error) {
        console.log(error);

    }
}

export const deleteItem = async (item) => {
    try {
      const { data } = await axios.put(
        `${REACT_APP_BACKEND_URL}/sales/items/deleteItem`,
        item
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  export const deleteAllItems = async () => {
    try {
      const { data } = await axios.put(
        `${REACT_APP_BACKEND_URL}/sales/items/deleteAllItem`
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  };




export const addCategory = async (categoryData) => {
    try {
        const data = await axios.post(`${REACT_APP_BACKEND_URL}/sales/items/addCategory`, categoryData)
        return data
    } catch (error) {
        console.log(error);

    }
}

export const getAllParentCategories = async () => {
    try {
        const data = await axios.get(`${REACT_APP_BACKEND_URL}/sales/items/getAllParentCategories`)
        return data

    } catch (error) {
        console.log(error);

    }
}

export const getAllCategories = async () => {
    try {
        const { data } = await axios.get(`${REACT_APP_BACKEND_URL}/sales/items/getAllCategories`)
        return data

    } catch (error) {
        console.log(error);

    }
}



export const fetchAllUnits = async () => {
    try {
        const data = await axios.get(`${REACT_APP_BACKEND_URL}/sales/items/getAllUnits`)
        return data;

    } catch (error) {
        console.log(error);

    }
}

export const editItem = async (itemId, formDataObj) => {
    try {
        const data = await axios.put(`${REACT_APP_BACKEND_URL}/sales/items/editItem/${itemId}`, formDataObj)
        return data;

    } catch (error) {
        console.log(error);

    }
}


export const addUnit = async (unitData) => {
    try {
        const data = await axios.post(`${REACT_APP_BACKEND_URL}/sales/items/addUnit`, unitData)
        return data

    } catch (error) {
        console.log(error);

    }
}