import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";

import rootSaga from "./sagas/rootSaga";
import customerReducer from "./ducks/customerSlice";
import estimateReducer from "./ducks/estimateSlice";
import invoiceReducer from "./ducks/invoiceSlice";
import itemReducer from "./ducks/itemSlice";
import salesOrderReducer from "./ducks/salesOrderSlice";
import receiptsReducer from "./ducks/receiptSlice";
import taxesReducer from "./ducks/taxSlice";
import adminReducer from "./ducks/adminSlice";
import vendorReducer from "./ducks/vendorSlice";
import purchaseReducer from "./ducks/purchaseOrderSlice";
import billReducer from "./ducks/billSlice";
import paymentReceiptsReducer from "./ducks/paymentReceiptsSlice";
import goodsReceiveNoteReducer from "./ducks/goodsReceiveNoteSlice";
import countryReducer from "./ducks/countrySlice";
import bankingReducer from "./ducks/bankingSlice";
import chartOfAccountReducer from "./ducks/chartOfAccountsSlice";
import jouranlEntryReducer from "./ducks/journalEntrySlice";
import unloadStockReducer from "./ducks/unloadSlice";

import templateReducer from "./ducks/templateSlice";
// import creditNoteReducer from "./ducks/creditNoteSlice";
import vendorCreditReducer from "./ducks/vendorCreditSlice";
import profileReducer from "./ducks/profileSlice";

import creditNoteReducer from "./ducks/creditNoteSlice";
import vehicleReducer from "./ducks/vehicleSlice";
import routeReducer from "./ducks/routeSlice";
import staffReducer from "./ducks/staffSlice";
import ordersReducer from "./ducks/orderslice";
import reportReducer from "./ducks/reportSlice";
import stockReducer from "./ducks/stockSlice";
import prefixReducer from "./ducks/prefixSlice";

const sagaMiddleware = createSagaMiddleware();
const loggerMiddleware = createLogger();

const reducer = combineReducers({

  customers: customerReducer,
  estimates: estimateReducer,
  invoices: invoiceReducer,
  items: itemReducer,
  salesOrders: salesOrderReducer,
  receipts: receiptsReducer,
  taxes: taxesReducer,
  admin: adminReducer,
  vendors: vendorReducer,
  purchaseOrders: purchaseReducer,
  bill: billReducer,
  paymentReceipts: paymentReceiptsReducer,
  goodsReceiveNotes: goodsReceiveNoteReducer,
  country: countryReducer,
  banking: bankingReducer,
  chartOfAccounts: chartOfAccountReducer,
  journalEntrys: jouranlEntryReducer,
  vehicles: vehicleReducer,
  route: routeReducer,
  staff: staffReducer,
  orders: ordersReducer,
  report: reportReducer,
  stock: stockReducer,
  template: templateReducer,
  vendorCredits: vendorCreditReducer,
  profile: profileReducer,
  creditNotes: creditNoteReducer,
  prefix:prefixReducer,
  unloadStock:unloadStockReducer
});

const store = configureStore({
  reducer,
  middleware: [
    ...getDefaultMiddleware({ thunk: false }),
    sagaMiddleware,
    loggerMiddleware,
  ],
});

sagaMiddleware.run(rootSaga);

export default store;
