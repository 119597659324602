import { createSlice } from "@reduxjs/toolkit";
const ordersSlice = createSlice({
  name: "orders",
  initialState: {
    orders:[]
  },

  reducers: {
    setOrders(state, action) {
      const orders = action.payload;
      return { ...state,orders:orders };
    },
    resetOrders(state, action) {
      return null; // Reset the state to initial state (null)
    },
   
    addOrders() {},
    getOrders(){},
    endOrders(){}
  },
});

export const { setOrders ,addOrders,getOrders,endOrders,resetOrders} = ordersSlice.actions;

export default  ordersSlice.reducer;
