import axios from "axios";
import { ADMIN_BACKEND_URL } from "../../../../config/config";
export function addMainRouteRequest({
    payload: {
      token,
      formValues: { ...data },
    },
  }) {
    return axios.request({
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "post",
      data: JSON.stringify({ data }),
      url: `${ ADMIN_BACKEND_URL}/admin/add-mainroute`,
    });
  }
  export function addSubRouteRequest({
    payload: {
      token,
      formValues: { ...data },
    },
  }) {
    return axios.request({
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "post",
      data: JSON.stringify({ data }),
      url: `${ ADMIN_BACKEND_URL}/admin/add-subRoute`,
    });
  }
  export function getMainRouteRequest() {
  return axios.request({
    headers: {
      "Content-type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
    method: "get",
    url: `${ADMIN_BACKEND_URL}/admin/main-route`,
  });
}
export function getSubRouteRequest() {
  return axios.request({
    headers: {
      "Content-type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
    method: "get",
    url: `${ADMIN_BACKEND_URL}/admin/sub-route`,
  });
}