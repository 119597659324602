import axios from "axios";
import { ADMIN_BACKEND_URL } from "../../../../config/config";

export function getSalesListRequest({payload}) {
    console.log('oombal');
    console.log(payload);
    const fromDate = payload.fromDate
    const toDate = payload.toDate

  return axios.request({
    headers: {
      "Content-type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
    method: "get",
    url:`${ADMIN_BACKEND_URL}/admin/sales-list?from=${fromDate}&to=${toDate}`,
  });
}
// export function getUsersRequest() {
//   return axios.request({
//     headers: {
//       "Content-type": "application/json",
//       // Authorization: `Bearer ${token}`,
//     },
//     method: "get",
//     url: `${ADMIN_BACKEND_URL}/admin/users`,
//   });
// }