import axios from "axios";
import { ADMIN_BACKEND_URL } from "../../../../config/config";
export function addVehicleRequest({
    payload: {
      token,
      formValues: { ...data },
    },
  }) {
    return axios.request({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "post",
      data: JSON.stringify({ data }),
      url: `${ ADMIN_BACKEND_URL}/admin/add-vehicle`,
    });
  }
  export function getVehicleRequest() {
    return axios.request({
      headers: {
        "Content-type": "application/json",
        // Authorization: `Bearer ${token}`,
      },
      method: "get",
      url: `${ADMIN_BACKEND_URL}/admin/vehicles`,
    });
  }