import { call, put } from "redux-saga/effects";
// import { addMainRouteRequest } from "../../requests/routeRequest/RouteRequest";
import {
  addMainRouteRequest,
  addSubRouteRequest,
  getMainRouteRequest,
  getSubRouteRequest
} from "../../requests/routeRequest/RouteRequest";
import { setMainRoute ,setSubRoute} from "../../../ducks/routeSlice";
export function* handleAddMainRoute(action) {
  console.log(action);
  try {
    const {
      data: { email, token },
    } = yield call(addMainRouteRequest, action);

    //   localStorage.setItem("user", JSON.stringify({ email, token }));

    //   yield put(setLoginError(null));
    //   yield put(setUser(email));
  } catch (error) {
    const {
      response: { data },
    } = error;

    console.log(data);
    //   yield put(setLoginError(data));
  }
}

export function* handleAddSubRoute(action) {
  console.log(action);
  try {
    const {
      data: { email, token },
    } = yield call(addSubRouteRequest, action);

    //   localStorage.setItem("user", JSON.stringify({ email, token }));

    //   yield put(setLoginError(null));
    //   yield put(setUser(email));
  } catch (error) {
    const {
      response: { data },
    } = error;

    console.log(data);
    //   yield put(setLoginError(data));
  }
}

export function* handleGetMainRoute(action) {
  console.log(action);
  try {
    const { data } = yield call(getMainRouteRequest, action);
    if (data) {
      yield put(setMainRoute(data));
    }
    console.log("no working");
    console.log(data);
  } catch (error) {
    const {
      response: { data },
    } = error;

    console.log(data);
    //   yield put(setLoginError(data));
  }
}

export function* handleGetSubRoute(action) {
  console.log(action);
  try {
    const { data } = yield call(getSubRouteRequest, action);
    if (data) {
      yield put(setSubRoute(data));
    }
    console.log("no working");
    console.log(data);
  } catch (error) {
    const {
      response: { data },
    } = error;

    console.log(data);
    //   yield put(setLoginError(data));
  }
}
