import "./staffdetails.css";
import Box from "@mui/material/Box";
import TabPanel from "@mui/lab/TabPanel";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CommonTab from "../../components/CommonTab/CommonTab";

import { getStaff } from "../../redux/ducks/staffSlice";
import SubNavBar from "../../components/SubNavbar/SubNavbar";
import * as Yup from "yup";
import Input from "../../components/Input/Input";
import { getPrefix } from "../../services/prefixServices";
import { setPrefix } from "../../redux/ducks/prefixSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalComponent from "../../components/Modal/ModalComponent";
import { addPrefix } from "../../services/prefixServices";

const tabHeadContent = [
  { value: "1", label: "Order-no prefix" },
  { value: "2", label: "Route prefix" },
  { value: "3", label: "Invoice prefix" },
  // { value: "4", label: "Transfers Prefix" },
  { value: "5", label: "Journal prefix" },
  // { value: "6", label: "Purchase prefix" },
  { value: "7", label: "Payment prefix" },
];

const baseStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
};
const orderschema = Yup.object().shape({
  order: Yup.string().required("Please,add a order prefix"),
});

const initialOrderPrefix = {
  order: "",
};
const routePrefixschema = Yup.object().shape({
  route: Yup.string().required("Please,add a route prefix"),
});

const initialRoutePrefix = {
  routePrefix: "",
};
const subRoutePrefixschema = Yup.object().shape({
  subRoutePrefix: Yup.string().required("Please,add a sub-route prefix"),
});

const initialSubRoutePrefix = {
  subRoutePrefix: "",
};
const transferSchema = Yup.object().shape({
  transfer: Yup.string().required("Please,add a transfer-stock prefix"),
});
const journalSchema = Yup.object().shape({
  journal: Yup.string().required("Please,add a journal prefix"),
});
const invoiceSchema = Yup.object().shape({
  invoice: Yup.string().required("Please,add a invoice prefix"),
});
const paymentSchema = Yup.object().shape({
  payment: Yup.string().required("Please,add a payment prefix"),
});
const purchaseSchema = Yup.object().shape({
  purchase: Yup.string().required("Please,add a purchase prefix"),
});

const initialTransfersPrefix = {
  transferPrefix: "",
};
function DeliveryPrefix() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [refresh, setRefrsh] = useState(false);

  const [formOrder, setFormOrder] = useState();
  const [formRoute, setFormRoute] = useState();
  const [formSubRoute, setFormSubRoute] = useState(initialSubRoutePrefix);
  const [formTransfer, setFormTransfer] = useState(initialTransfersPrefix);
  const [formJournal, setFormJournal] = useState(initialTransfersPrefix);
  const [formInvoice, setFormInvoice] = useState();
  const [formPayment, setFormPayment] = useState();
  const [formPurchase, setFormPurchase] = useState();
  const { prefix } = useSelector((state) => state.prefix);

  const [formErrors, setFormErrors] = useState();

  const [value, setValue] = useState("1");
  const [loading, setLoading] = useState(false);

  const [url, setUrl] = useState("order-no prefix");

  const handleChange = (event, newValue) => {
    const urlMappings = {
      "1": "order-no prefix",
      "2": "route prefix",
      "3": "invoice prefix",
      "4": "transefer prefix",
      "5": "journal prefix",
      "6": "purchase prefix",
      "7": "payment prefix",
    };
  
    console.log(newValue, event, "tab console");
    setValue(newValue);
  
    // Set the URL based on the mapping
    setUrl(urlMappings[newValue] || "");
  };
  

  const goBack = () => {
    navigate(-1);
  };
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
  
    try {
      const formValues = {
        "1": formOrder,
        "2": formRoute,
        "3": formInvoice,
        "4": formTransfer,
        "5": formJournal,
        "6": formPurchase,
        "7": formPayment,
      };
  
      const validationSchemas = {
        "1": orderschema,
        "2": routePrefixschema,
        "3": invoiceSchema,
        "4": transferSchema,
        "5": journalSchema,
        "6": purchaseSchema,
        "7": paymentSchema,
      };
  
      await validationSchemas[value].validate(formValues[value], { abortEarly: false });
      await addPrefix(formValues[value]);
      setRefrsh(!refresh);
      
      setLoading(false);
      setOpen(false);
      
      toast.success(`updated ${url} successfully`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      setOpen(false);
      if (error.inner) {
        const validationErrors = {};
        error.inner.forEach((innerError) => {
          validationErrors[innerError.path] = innerError.message;
        });
        setFormErrors(validationErrors);
      } else {
        setOpen(false);
        toast.error(error.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };
  
  const handleOpen = () => {
    setOpen(true);
  };

  const buttons = [
    {
      buttonName: "Cancel",
      buttonStyles:
        "tw-px-3 tw-h-8 tw-w-16 tw-mr-5 tw-py-1 tw-text-xs tw-font-medium tw-text-center tw-text-main tw-rounded-md tw-border tw-border-main",
      buttonFunction: goBack,
    },
    {
      buttonName: "Save",
      buttonStyles:
        "tw-px-3 tw-h-8 tw-w-16 tw-py-1 tw-text-xs tw-font-medium tw-text-center tw-text-white tw-rounded-md tw-border tw-border-main tw-bg-main",
      buttonFunction: handleOpen,
    },
  ];
  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    if (name === "order") {
      setFormOrder({ ...formOrder, [name]: value });
      setFormErrors({ ...formErrors, [name]: null });
    }
    if (name === "route") {
      setFormRoute({ ...formRoute, [name]: value });
      setFormErrors({ ...formErrors, [name]: null });
    }
    // if (name === "transfer") {
    //   setFormTransfer({ ...formTransfer, [name]: value });
    //   setFormErrors({ ...formErrors, [name]: null });
    // }
    if (name === "journal") {
      setFormJournal({ ...formJournal, [name]: value });
      setFormErrors({ ...formErrors, [name]: null });
    }
    if (name === "invoice") {
      setFormInvoice({ ...formInvoice, [name]: value });
      setFormErrors({ ...formErrors, [name]: null });
    }
    // if (name === "purchase") {
    //   setFormPurchase({ ...formPurchase, [name]: value });
    //   setFormErrors({ ...formErrors, [name]: null });
    // }
    if (name === "payment") {
      setFormPayment({ ...formPayment, [name]: value });
      setFormErrors({ ...formErrors, [name]: null });
    }
  };

  useEffect(() => {
    fetchPrefix();
  }, [refresh]);

  const fetchPrefix = async () => {
    const data = await getPrefix();
    if (data) {
      dispatch(setPrefix(data));
    }
    setLoading(false);
  };
  return (
    <>
      <div className="estimate-wrapper tw-mt-16">
        <Box sx={{ width: "100%" }}>
          {/* modal starting for confirmation */}
          <SubNavBar
            leftText={`set ${url}`}
            buttons={buttons}
            fullBorder={true}
          />

          <ModalComponent
            title={"SAVE"}
            onClose={(e) => setOpen(false)}
            open={open}
            fade={open}
            style={{ ...baseStyle }}
          >
            <div className="tw-px-6 tw-pt-3 tw-pb-6">
              <div className="tw-text-[12px]">
                Are you sure you want to save
              </div>
              <div className="tw-pt-3  tw-flex tw-items-end tw-justify-center tw-gap-5">
                <button
                  onClick={() => setOpen(!open)}
                  className="tw-px-3 tw-h-8 tw-w-24 tw-mr-5 tw-py-1 tw-text-xs tw-font-medium tw-text-center tw-text-main tw-rounded-md tw-border tw-border-main"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSubmit}
                  className={`tw-px-3 tw-h-8 tw-w-24 tw-py-1 tw-text-xs tw-font-medium tw-text-center tw-text-white tw-rounded-md tw-border tw-border-main tw-bg-main`}
                >
                  {loading ? "Please wait" : "Save"}
                </button>
              </div>
            </div>
          </ModalComponent>

          {/* modal ending for confirmation */}

          <div className="tw-flex tw-items-center tw-py-8 tw-px-14 tw-gap-32"></div>

          <CommonTab
            onChange={handleChange}
            value={value}
            tabHeadContent={tabHeadContent}
            dashboard={false}
          >
            <TabPanel
              sx={{ height: "70vh", padding: "0px 3.5rem", marginTop: "10px" }}
              value="1"
            >
              <div className="tw-flex tw-flex-row tw-justify-center tw-items-center">
                <div
                  className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-4 tw-w-1/2 tw-h-48 tw-p-8 tw-bg-[#FCFBFF] tw-rounded-3xl tw-shadow"
                  style={{ overflowY: "auto" }}
                >
                  <h2 className="tw-text-lg tw-font-Manrope tw-font-semibold">
                    OrderNo Prefix
                  </h2>
                  <p className="tw-text-sm tw-font-Manrope tw-text-gray-600">
                    Current the order prefix :
                    <span className="tw-text-sm tw-font-Manrope tw-font-semibold tw-text-main">
                      {prefix && prefix?.order ? prefix.order : "A"}
                    </span>
                  </p>
                  <p className="tw-text-sm tw-font-Manrope tw-text-gray-600">
                    Enter new order-no prefix below:
                  </p>
                  <Input
                    name={"order"}
                    value={formOrder?.order}
                    placeholder={"Enter here"}
                    onChange={handleInputChange}
                    type={"text"}
                    errorMessage={formErrors?.order}
                    style={{
                      height: "30px",
                      fontSize: "12px",
                      width: "200px",
                    }}
                  />
                </div>
              </div>
            </TabPanel>

            <TabPanel
              value="2"
              sx={{ height: "70vh", padding: "0px 3.5rem", marginTop: "10px" }}
            >
              <div className="tw-flex tw-flex-row tw-justify-center tw-items-center">
                <div
                  className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-4 tw-w-1/2 tw-h-48 tw-p-8 tw-bg-[#FCFBFF] tw-rounded-3xl tw-shadow"
                  style={{ overflowY: "auto" }}
                >
                  <h2 className="tw-text-lg tw-font-Manrope tw-font-semibold">
                    Route Prefix
                  </h2>
                  <p className="tw-text-sm tw-font-Manrope tw-text-gray-600">
                    Current the route prefix :
                    <span className="tw-text-sm tw-font-Manrope tw-font-semibold tw-text-main">
                      {prefix && prefix?.route ? prefix?.route : "RC"}
                    </span>
                  </p>
                  <p className="tw-text-sm tw-font-Manrope tw-text-gray-600">
                    Enter new route prefix below:
                  </p>
                  <Input
                    name={"route"}
                    value={formRoute?.route}
                    placeholder={"Enter here"}
                    onChange={handleInputChange}
                    errorMessage={formErrors?.route}
                    type={"text"}
                    style={{
                      height: "30px",
                      fontSize: "12px",
                      width: "200px",
                    }}
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel
              value="3"
              sx={{ height: "70vh", padding: "0px 3.5rem", marginTop: "10px" }}
            >
              <div className="tw-flex tw-flex-row tw-justify-center tw-items-center">
                <div
                  className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-4 tw-w-1/2 tw-h-48 tw-p-8 tw-bg-[#FCFBFF] tw-rounded-3xl tw-shadow"
                  style={{ overflowY: "auto" }}
                >
                  <h2 className="tw-text-lg tw-font-Manrope tw-font-semibold">
                    Invoice Prefix
                  </h2>
                  <p className="tw-text-sm tw-font-Manrope tw-text-gray-600">
                    Current the invoice prefix :
                    <span className="tw-text-sm tw-font-Manrope tw-font-semibold tw-text-main">
                      {prefix && prefix?.invoice ? prefix?.invoice : "INV"}
                    </span>
                  </p>
                  <p className="tw-text-sm tw-font-Manrope tw-text-gray-600">
                    Enter new sub-route prefix below:
                  </p>
                  <Input
                    name={"invoice"}
                    value={formInvoice?.invoice}
                    placeholder={"Enter here"}
                    onChange={handleInputChange}
                    errorMessage={formErrors?.invoice}
                    type={"text"}
                    style={{
                      height: "30px",
                      fontSize: "12px",
                      width: "200px",
                    }}
                  />
                </div>
              </div>
            </TabPanel>
            {/* <TabPanel
              value="4"
              sx={{ height: "70vh", padding: "0px 3.5rem", marginTop: "10px" }}
            >
              <div className="tw-flex tw-flex-row tw-justify-center tw-items-center">
                <div
                  className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-4 tw-w-1/2 tw-h-48 tw-p-8 tw-bg-[#FCFBFF] tw-rounded-3xl tw-shadow"
                  style={{ overflowY: "auto" }}
                >
                  <h2 className="tw-text-lg tw-font-Manrope tw-font-semibold">
                    Transfer Prefix
                  </h2>
                  <p className="tw-text-sm tw-font-Manrope tw-text-gray-600">
                    Current Transfer prefix :
                    <span className="tw-text-sm tw-font-Manrope tw-font-semibold tw-text-main">
                      {prefix && prefix?.transfer ? prefix?.transfer : "TS"}
                    </span>
                  </p>
                  <p className="tw-text-sm tw-font-Manrope tw-text-gray-600">
                    Enter new transfer prefix below:
                  </p>
                  <Input
                    name={"transfer"}
                    value={formTransfer?.transfer}
                    placeholder={"Enter here"}
                    onChange={handleInputChange}
                    errorMessage={formErrors?.transfer}
                    type={"text"}
                    style={{
                      height: "30px",
                      fontSize: "12px",
                      width: "200px",
                    }}
                  />
                </div>
              </div>
            </TabPanel> */}
            <TabPanel
              value="5"
              sx={{ height: "70vh", padding: "0px 3.5rem", marginTop: "10px" }}
            >
              <div className="tw-flex tw-flex-row tw-justify-center tw-items-center">
                <div
                  className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-4 tw-w-1/2 tw-h-48 tw-p-8 tw-bg-[#FCFBFF] tw-rounded-3xl tw-shadow"
                  style={{ overflowY: "auto" }}
                >
                  <h2 className="tw-text-lg tw-font-Manrope tw-font-semibold">
                    Journal Prefix
                  </h2>
                  <p className="tw-text-sm tw-font-Manrope tw-text-gray-600">
                    Current journal prefix :
                    <span className="tw-text-sm tw-font-Manrope tw-font-semibold tw-text-main">
                      {prefix && prefix?.journal ? prefix?.journal : "jou"}
                    </span>
                  </p>
                  <p className="tw-text-sm tw-font-Manrope tw-text-gray-600">
                    Enter new Journal prefix below:
                  </p>
                  <Input
                    name={"journal"}
                    value={formJournal?.journal}
                    placeholder={"Enter here"}
                    onChange={handleInputChange}
                    errorMessage={formErrors?.journal}
                    type={"text"}
                    style={{
                      height: "30px",
                      fontSize: "12px",
                      width: "200px",
                    }}
                  />
                </div>
              </div>
            </TabPanel>
            {/* <TabPanel
              value="6"
              sx={{ height: "70vh", padding: "0px 3.5rem", marginTop: "10px" }}
            >
              <div className="tw-flex tw-flex-row tw-justify-center tw-items-center">
                <div
                  className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-4 tw-w-1/2 tw-h-48 tw-p-8 tw-bg-[#FCFBFF] tw-rounded-3xl tw-shadow"
                  style={{ overflowY: "auto" }}
                >
                  <h2 className="tw-text-lg tw-font-Manrope tw-font-semibold">
                    Purchase Prefix
                  </h2>
                  <p className="tw-text-sm tw-font-Manrope tw-text-gray-600">
                    Current the purchase prefix :
                    <span className="tw-text-sm tw-font-Manrope tw-font-semibold tw-text-main">
                      {prefix && prefix?.purchase ? prefix?.purchase : "pur"}
                    </span>
                  </p>
                  <p className="tw-text-sm tw-font-Manrope tw-text-gray-600">
                    Enter new Purchase prefix below:
                  </p>
                  <Input
                    name={"purchase"}
                    value={formPurchase?.purchase}
                    placeholder={"Enter here"}
                    onChange={handleInputChange}
                    errorMessage={formErrors?.purchase}
                    type={"text"}
                    style={{
                      height: "30px",
                      fontSize: "12px",
                      width: "200px",
                    }}
                  />
                </div>
              </div>
            </TabPanel> */}
            <TabPanel
              value="7"
              sx={{ height: "70vh", padding: "0px 3.5rem", marginTop: "10px" }}
            >
              <div className="tw-flex tw-flex-row tw-justify-center tw-items-center">
                <div
                  className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-4 tw-w-1/2 tw-h-48 tw-p-8 tw-bg-[#FCFBFF] tw-rounded-3xl tw-shadow"
                  style={{ overflowY: "auto" }}
                >
                  <h2 className="tw-text-lg tw-font-Manrope tw-font-semibold">
                    Payment Prefix
                  </h2>
                  <p className="tw-text-sm tw-font-Manrope tw-text-gray-600">
                    Current the payment prefix :
                    <span className="tw-text-sm tw-font-Manrope tw-font-semibold tw-text-main">
                      {prefix && prefix?.payment ? prefix?.payment : "pur"}
                    </span>
                  </p>
                  <p className="tw-text-sm tw-font-Manrope tw-text-gray-600">
                    Enter new Payment prefix below:
                  </p>
                  <Input
                    name={"payment"}
                    value={formPayment?.payment}
                    placeholder={"Enter here"}
                    onChange={handleInputChange}
                    errorMessage={formErrors?.payment}
                    type={"text"}
                    style={{
                      height: "30px",
                      fontSize: "12px",
                      width: "200px",
                    }}
                  />
                </div>
              </div>
            </TabPanel>
          </CommonTab>
          <ToastContainer></ToastContainer>
        </Box>
      </div>
    </>
  );
}

export default DeliveryPrefix;
