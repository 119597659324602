import { createSlice } from "@reduxjs/toolkit";
const unloadStockSlice = createSlice({
  name: "unloadStock",
  initialState: {
    unloadStocks:[]
  },

  reducers: {
    setUnloadStock(state, action) {
      const unloadStock = action.payload;
      return { ...state,unloadStocks:unloadStock };
    },
  
   
  },
});

export const { setUnloadStock } = unloadStockSlice.actions;

export default  unloadStockSlice.reducer;
