import { call, put } from "redux-saga/effects";
import { setCustomer ,setCustomers,setSingleCustomers} from "../../../ducks/customerSlice";
import { requestGetAllCustomer } from "../../requests/customerRequest/getAllCustomerRequest";
import { getCustomersRequest ,getACustomerDetailRequest} from "../../requests/customerRequest/getAllCustomerRequest";

export function* handleGetAllCustomer(action) {
  try {
    const data = yield call(requestGetAllCustomer);
    console.log(data,'data inside the redux');
    yield put(setCustomer({ ...data }));
  } catch (error) {
    console.log(error);
  }
}
export function* handleGetCustomers(action) {
  try {
    const {data }= yield call(getCustomersRequest);
    console.log(data,'data inside the redux');
    yield put(setCustomers(data));
  } catch (error) {
    console.log(error);
  }
}
export function* handleGetACustomerDetail(action) {
  console.log(action);
  try {
    const {data }= yield call(getACustomerDetailRequest,action);
    console.log(data,'data inside the redux');
    yield put(setSingleCustomers(data));
  } catch (error) {
    console.log(error);
  }
}
