import { createSlice } from "@reduxjs/toolkit";
const reportSlice = createSlice({
  name: "report",
  initialState: {
    reports: null,
    salesList: [],
  },

  reducers: {
    setReport(state, action) {
      const report = action.payload;
      return { ...state, reports: report };
    },
    setSaleslist(state, action) {
      const salesList = action.payload;
      return { ...state, salesList: salesList };
    },
    resetReport(state, action) {
      return null; // Reset the state to initial state (null)
    },

    addReport() {},
    getReport() {},
    getSalesList() {},
    endReport() {},
  },
});

export const {
  setReport,
  addReport,
  getReport,
  endReport,
  resetReport,
  getSalesList,
  setSaleslist,
} = reportSlice.actions;

export default reportSlice.reducer;
