import { createSlice } from "@reduxjs/toolkit";
const vehicleSlice = createSlice({
  name: "vehicle",
  initialState: {
    vehicles:[]
  },

  reducers: {
    setVehicle(state, action) {
      const vehicle = action.payload;
      return { ...state,vehicles:vehicle };
    },
    resetVehicle(state, action) {
      return null; // Reset the state to initial state (null)
    },
   
    addVehicle() {},
    getVehicle(){},
    endVehicle(){}
  },
});

export const { setVehicle ,addVehicle,getVehicle,endVehicle,resetVehicle} = vehicleSlice.actions;

export default  vehicleSlice.reducer;
