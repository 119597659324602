import axios from "axios";
import { REACT_APP_BACKEND_URL } from "../config/config";
import { ADMIN_BACKEND_URL } from "../config/config";

// export const addNewCustomer = async (formDataObj, customerId) => {
//     try {
//         console.log(customerId);
//         const data = await axios.post(`${REACT_APP_BACKEND_URL}/sales/customers/addNewCustomer?id=${customerId}&folderName=Customers`, formDataObj)
//         return data;

//     } catch (error) {
//         console.log(error);

//     }
// }
export const addNewCustomer = async (formDataObj, customerId) => {
  try {
    console.log(customerId);
    const data = await axios.post(
      `${REACT_APP_BACKEND_URL}/sales/customers/addNewCustomer?id=${customerId}&folderName=Customers`,
      formDataObj
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchAllCustomers = async () => {
  try {
    const data = await axios.get(
      `${REACT_APP_BACKEND_URL}/sales/customers/getAllCustomer`
    );
    console.log(data, "data");
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchOneCustomer = async (clientId) => {
  try {
    const params = {
      folderName: "Customers", // Set the folder name
    };

    const config = {
      params: params,
    };
    const data = await axios.get(
      `${REACT_APP_BACKEND_URL}/sales/customers/getCustomerProfile/${clientId}`,
      config
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const editCustomer = async (customerId, formDataObj) => {
  try {
    const data = await axios.put(
      `${REACT_APP_BACKEND_URL}/sales/customers/editCustomer/${customerId}`,
      formDataObj
    );

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteCustomer = async (customer) => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_BACKEND_URL}/sales/customers/deleteCustomer`,
      customer
    );
    console.log(data, "in serive");
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteAllCustomers = async () => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_BACKEND_URL}/sales/customers/delete-all-customer`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllCustomerTransactions = async () => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_BACKEND_URL}/sales/customers/get-all-customer-transactions`
    );

    return data;
  } catch (error) {
    console.log(error);
  }
};
export function addCustomerRequest(data) {
  const formData = new FormData();
  console.log("working in image");
  for (const key in data) {
    console.log(key, data[key]);
    formData.append(key, data[key]);
  }

  console.log(formData);
  const config = {
    headers: {
      "Content-type": "application/json",

      //   Authorization: `Bearer ${token}`,
    },
  };

  return axios.post(
    `${ADMIN_BACKEND_URL}/admin/add-customer`,
    formData,
    config
  );
}

export function editCustomerRequest(data, id) {
  const config = {
    headers: {
      "Content-type": "application/json",

      //   Authorization: `Bearer ${token}`,
    },
  };

  return axios.post(
    `${ADMIN_BACKEND_URL}/admin/edit-customer/${id}`,
    data,
    config
  );
}

export function undoDeleteCustomerRequest(id) {

  const config = {
    headers: {
      "Content-type": "application/json",

      //   Authorization: `Bearer ${token}`,
    },
  };

  return axios.put(`${ADMIN_BACKEND_URL}/admin/undo-customer/${id}`, config);
}

export function softDeleteCustomerRequest(id) {
  console.log(id, "idfdgdfgsdgdf");

  const config = {
    headers: {
      "Content-type": "application/json",

      //   Authorization: `Bearer ${token}`,
    },
  };

  return axios.post(`${ADMIN_BACKEND_URL}/admin/soft-customer/${id}`, config);
}
export function hardDeleteCustomerRequest(id) {
  console.log(id, "idfdgdfgsdgdf");

  const config = {
    headers: {
      "Content-type": "application/json",

      //   Authorization: `Bearer ${token}`,
    },
  };

  return axios.delete(`${ADMIN_BACKEND_URL}/admin/hard-customer/${id}`, config);
}

export const getDeletedCustomers = async () => {
  try {
    const { data } = await axios.get(
      `${ADMIN_BACKEND_URL}/admin/deleted-customers`
    );

    return data;
  } catch (error) {
    console.log(error);
  }
};
export const fetchAllCustomersTransactions = async (cusId) => {
  try {
    const {data} = await axios.get(
      `${ADMIN_BACKEND_URL}/admin/get-all-customers-transactions/${cusId}`,
      // { headers: { token: token } }
    );
    return data;
  } catch (error) {
    throw error
    console.log(error);
  }
};
export const fetchAllCustomersFullDetails = async (cusId) => {
  try {
    const {data} = await axios.get(
      `${ADMIN_BACKEND_URL}/admin/customer/${cusId}`,
      // { headers: { token: token } }
    );
    return data;
  } catch (error) {
    throw error
    console.log(error);
  }
};
