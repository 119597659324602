import React, { useState,useEffect } from "react";
import "./dashboardNavbar.css";
import { MdArrowDropDown, MdOutlineArrowBackIos } from "react-icons/md";

import { FiMoreHorizontal } from "react-icons/fi";
import { BiEditAlt } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

function DashboardNavbar({
  url,
  value,
  onModalOpen = null,
  tabLinks,
  backButton,
  rounded,
  selectedRow,
  handleNavigation,
  handleDelete,
  input,
  tax = false,
  isDeleted,
  handleShowCustomers,
  options,
  performAction,
}) {
  const [active, setActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [selectedOption, setSelectedOption] = useState(null); // Initialize selectedOption as null

  useEffect(() => {
    // Set the initial selected option when options change
    if (options && options.length > 0) {
      setSelectedOption(options[0]);
    }
  }, []);
  const navigate = useNavigate();
  if (tax) {
    return (
      <div
        className={`tw-h-14 tw-flex tw-justify-start tw-items-center tw-w-3/6 tw-absolute tw-left-[201px] tw-top-[168px] `}
      >
        <div>
          {selectedRow && selectedRow?.length > 0 && (
            <button
              onClick={handleDelete}
              className="tw-relative tw-ml-7 tw-px-3 tw-h-8 tw-py-[5px] tw-text-center tw-text-main tw-bg-white tw-rounded-[5px] tw-drop-shadow-lg"
            >
              <AiOutlineDelete />
            </button>
          )}
          {selectedRow && selectedRow?.length === 1 && (
            <button
              onClick={handleNavigation}
              className="tw-relative tw-ml-7 tw-px-3 tw-h-8 tw-py-[5px] tw-text-center tw-text-main tw-bg-white tw-rounded-[5px] tw-drop-shadow-lg"
            >
              <BiEditAlt />
            </button>
          )}
        </div>
      </div>
    );
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    console.log(option);
    setSelectedOption(option);
    setIsOpen(false); // Close the dropdown after selecting an option
    performAction(option);
  };
console.log(selectedOption);
  const dropdownMenu = (
    <div
      className={`tw-mt-2 tw-w-36 tw-bg-white tw-rounded-md tw-shadow-lg tw-absolute tw-z-10 ${
        isOpen ? "tw-block" : "tw-hidden"
      }`}
    >
      {options&&options?.map((option, index) => (
        <div
          key={index}
          className="tw-py-1 tw-px-2 tw-text-xs tw-font-medium tw-text-start hover:tw-bg-sub tw-cursor-pointer"
          onClick={() => handleOptionClick(option)}
        >
          {option}
        </div>
      ))}
    </div>
  );
  return (
    <>
      <div className={` tw-z-40 tw-fixed tw-w-[80vw] tw-bg-mud`}>
        <div
          className={`tw-h-14    tw-flex ${
            rounded && "tw-rounded-t-[30px]"
          } lg:tw-gap-6 lg:tw-flex-row lg:tw-items-center tw-bg-white tw-px-14`}
        >
          <div
            className={`tw-h-14 tw-flex tw-justify-start tw-items-center tw-w-3/6`}
          >
            <div className={`${input && "tw-w-1/2"}`}>
              {backButton && (
                <button
                  onClick={() => navigate(-1)}
                  className="tw-px-3 tw-mr-7 tw-h-8 tw-py-1 tw-text-xs tw-font-medium tw-text-start tw-text-main tw-bg-white tw-rounded-md tw-drop-shadow-lg"
                >
                  <MdOutlineArrowBackIos className="tw-inline" />
                </button>
              )}
              {!input && (
                <div className="tw-relative tw-inline-block">
                  <button
                    className="tw-px-3 tw-h-8 tw-w-36 tw-py-1 tw-text-xs tw-font-medium tw-text-start tw-text-main tw-bg-white tw-rounded-md tw-drop-shadow-lg"
                    onClick={toggleDropdown}
                  >
                    {selectedOption || "All"}
                    <MdArrowDropDown
                      className={`tw-inline ${isOpen ? "tw-rotate-180" : ""}`}
                    />
                  </button>
                  {isOpen && dropdownMenu}
                </div>
              )}
              {input && input?.input}
            </div>
            {/* {tax && ( // Render this part only when tax is true */}

            <div>
              {selectedRow && selectedRow?.length > 0 && (
                <button
                  onClick={handleDelete}
                  className="tw-relative tw-ml-7 tw-px-3 tw-h-8 tw-py-[5px] tw-text-center tw-text-main tw-bg-white tw-rounded-[5px] tw-drop-shadow-lg"
                >
                  <AiOutlineDelete />
                </button>
              )}
              {selectedRow && selectedRow?.length === 1 && (
                <button
                  onClick={handleNavigation}
                  className="tw-relative tw-ml-7 tw-px-3 tw-h-8 tw-py-[5px] tw-text-center tw-text-main tw-bg-white tw-rounded-[5px] tw-drop-shadow-lg"
                >
                  <BiEditAlt />
                </button>
              )}
            </div>
            {/* )} */}
          </div>
          <div
            className={`tw-w-3/6 tw-flex tw-justify-end tw-items-center tw-h-14`}
          >
            {value == "1" ? (
              <button
                onClick={() => {
                  tabLinks?.length > 0 ? setActive(!active) : navigate(url);
                }}
                className="tw-px-3 tw-h-8 tw-w-28 tw-py-1 tw-text-xs tw-font-medium tw-text-center tw-text-white tw-bg-main tw-rounded-md tw-border tw-border-main"
              >
                +Add new 
                <span className="dropdown-icon">
                  <MdArrowDropDown className="tw-inline" />
                </span>
              </button>
            ) : onModalOpen !== null ? (
              <button
                onClick={onModalOpen}
                className="tw-px-3 tw-h-8 tw-w-28 tw-py-1 tw-text-xs tw-font-medium tw-text-center tw-text-white tw-bg-main tw-rounded-md tw-border tw-border-main"
              >
                +Add new 
                <span className="dropdown-icon">
                  <MdArrowDropDown className="tw-inline" />
                </span>
              </button>
            ) : (
              <button
                onClick={() => {
                  tabLinks?.length > 0 ? setActive(!active) : navigate(url);
                }}
                className="tw-px-3 tw-h-8 tw-w-28 tw-py-1 tw-text-xs tw-font-medium tw-text-center tw-text-white tw-bg-main tw-rounded-md tw-border tw-border-main"
              >
                +Add new
                <span className="dropdown-icon">
                  <MdArrowDropDown className="tw-inline" />
                </span>
                {active && tabLinks?.length > 0 && (
                  <div className="tw-relative tw-w-32">
                    <ul className="tw-absolute tw-right-7 tw-top-3 tw-z-50 tw-w-full tw-bg-white tw-shadow-lg tw-rounded-md">
                      {tabLinks.map((x, idx) => (
                        <li
                          key={idx}
                          onClick={() => navigate(x.url)}
                          className={`tw-text-xs tw-h-8 tw-text-start tw-text-black hover:tw-bg-main hover:tw-text-white tw-p-2 tw-pl-4 ${
                            idx === 0 && "tw-rounded-t-md"
                          } ${
                            idx === tabLinks?.length - 1 && "tw-rounded-b-md"
                          }`}
                        >
                          {x.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </button>
            )}
            {/* <button className="tw-relative tw-ml-7 tw-px-3 tw-h-8 tw-py-[5px] tw-text-center tw-text-main tw-bg-white tw-rounded-[5px] tw-drop-shadow-lg">
              <FiMoreHorizontal />
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardNavbar;
