import { call, put } from "redux-saga/effects";
import { addVehicleRequest ,getVehicleRequest} from "../../requests/vehicleRequest/VehicleRequest";
import { setVehicle } from "../../../ducks/vehicleSlice";
export function* handleAddVehicle(action) {
    console.log(action);
    try {
      const {
        data: { email, token },
      } = yield call(addVehicleRequest, action);
  
    //   localStorage.setItem("user", JSON.stringify({ email, token }));
  
    //   yield put(setLoginError(null));
    //   yield put(setUser(email));
    } catch (error) {
      const {
        response: { data },
      } = error;
  
      console.log(data);
    //   yield put(setLoginError(data));
    }
  }
  
  export function* handleGetVehicle(action) {
    console.log(action);
    try {
      const { data } = yield call(getVehicleRequest, action);
      if (data) {
        yield put(setVehicle(data));
      }
      console.log("no working");
      console.log(data);
    } catch (error) {
      const {
        response: { data },
      } = error;
  
      console.log(data);
      //   yield put(setLoginError(data));
    }
  }