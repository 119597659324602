import axios from "axios";
import { ADMIN_BACKEND_URL } from "../../../../config/config";
export async function addStaffRequest(data) {
  try {
    const formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key]);
    }
    console.log(formData);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        //   Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${ADMIN_BACKEND_URL}/admin/add-staff`,
      formData,
      config
    );

    return response;
  } catch (error) {
    console.error("Error adding staff:", error);
    throw error;
  }
}

export function getStaffRequest() {
  return axios.request({
    headers: {
      "Content-type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
    method: "get",
    url: `${ADMIN_BACKEND_URL}/admin/staffs`,
  });
}
export function getUsersRequest() {
  return axios.request({
    headers: {
      "Content-type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
    method: "get",
    url: `${ADMIN_BACKEND_URL}/admin/users`,
  });
}
export async function editStaffRequest(data) {
  try {
    const formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key]);
    }
    console.log(formData);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        //   Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${ADMIN_BACKEND_URL}/admin/edit-staff`,
      formData,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
}
