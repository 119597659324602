import { ThemeProvider } from "@emotion/react";
import { CssBaseline, createTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const styles = {
  borderRadius: "8px",
  "& .MuiDataGrid-root": {
    // Decrease the height of cells
    // rowHeight: "10px", // This line is not needed
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "rgba(243, 246, 244, 1)",
    padding: "0px 1px",
  },
  "& .MuiDataGrid-columnHeadersInner": {
    width: "-webkit-fill-available",
    textAlign: 'start',
  },
  "& .css-yrdy0g-MuiDataGrid-columnHeaderRow": {
    width: "-webkit-fill-available",
    display: "flex",
    justifyContent: "space-between",
  },
  "& .css-k008qs": {
    width: "-webkit-fill-available",
    display: "flex",
    justifyContent: "space-between",
  },
  "& .MuiDataGrid-row": {
    cursor: "pointer",
    width: "-webkit-fill-available",
    display: "flex",
    justifyContent: "space-between",
    height: '5px', // Set the height directly for the row
    textAlign: 'start',
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    padding: "0px 0px",
    minHeight: '38px !important', /* Adjust the height to your preference */
    maxHeight: '38px !important',
  },
  "& .MuiDataGrid-virtualScrollerRenderZone": {
    width: "-webkit-fill-available",
  },
  "& .MuiDataGrid-cell:empty": {
    display: "contents",
  },
  "& .MuiSvgIcon-fontSizeMedium": {
    color: "#008955",
  },
  "& .MuiDataGrid-iconSeparator": {
    color: "#008955",
  },
  "& .MuiDataGrid-cell": {
    minHeight: '38px !important', /* Adjust the height to your preference */
    maxHeight: '38px !important', /* Adjust the height to your preference */
  }
};




const theme = createTheme({
  typography: {
    fontFamily: ["Manrope", "sans-serif"].join(","),
  },
});

const Table = ({
  rows,
  columns,
  handleRowClick,
  handleNavigation,
  listOfItems,
  dashboard,
  cellClassName
}) => {
  // console.log(rows,'rowsssssssssinside');
  return (
    
    <div className="tw-h-full tw-w-full tw-py-4" >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DataGrid
          // getRowId={(row) => row.id}
          sx={
            ({
              fontFamily: "Raleway",
              width:'600px'
            },
              styles)
          }
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 20 },
            },
          }}
          pageSizeOptions={[20, 40]}
          checkboxSelection={!dashboard}
          // rowCount={columns?.length - 1}
          // getRowClassName={getRowClassName}
          rowHeight={38}
          onRowClick={(e) => handleNavigation(e)}
          onRowSelectionModelChange={(e) => handleRowClick(e, listOfItems)}
          showCellVerticalBorder
        />
      </ThemeProvider>
    </div>
  );
};

export default Table;
