import { createSlice } from "@reduxjs/toolkit";

const prefixSlice = createSlice({
  name: "prefix",
  initialState: {},
  prefix:{},
  reducers: {
    setPrefix(state, action) {
      const prefix = action.payload;
      console.log(prefix);
      return { ...state, prefix };
    },
   
  },
});

export const { setPrefix, } = prefixSlice.actions;

export default prefixSlice.reducer;
