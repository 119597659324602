import axios from "axios";
import { REACT_APP_BACKEND_URL } from "../config/config";
import { ADMIN_BACKEND_URL } from "../config/config";

const token = localStorage.getItem('adminToken')

export const addPrefix = async (formDataObj) => {
    try {
        const data = await axios.post(
            `${ADMIN_BACKEND_URL}/prefix/prefix-setting`,
            formDataObj
        );
        return data;
    } catch (error) {
        console.log(error);
    }
};
export const getPrefix = async () => {
    try {
        const {data} = await axios.get(
            `${ADMIN_BACKEND_URL}/prefix/prefix-setting`,
          
        );
        return data;
    } catch (error) {
        console.log(error);
    }
};