import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { Box, Tab } from "@mui/material";
import React from "react";

const CommonTab = ({
  label,
  value,
  style,
  onChange,
  tabHeadContent,
  children,
  dashboard,
  buttons,
}) => {
  return (
    <>
      <div className={`${dashboard ? "" : "tw-bg-gray-"} tw-h-14`}>
        <div
          className={`${
            !dashboard && "tw-rounded-t-[30px] tw-bg-white tw-w-[80vw] tw-fixed"
          }`}
        >
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                // position: "fixed",
              }}
            >
              <TabList
                sx={{
                  height: "10px",
                  paddingLeft: "3.5rem",
                
                }}
                onChange={onChange}
                aria-label="lab API tabs example"
              >
                {tabHeadContent?.map((head) => (
                  <Tab
                    key={head.value}
                    icon={head.icon}
                    sx={{
                      fontSize: "14px",
                      textTransform: "capitalize",
                      // "&.Mui-selected": {
                      //   color: "red",
                      //   textDecorationColor: "red",
                      // },
                      // "& .MuiTabs-indicator.css-1aquho2-MuiTabs-indicator": {
                      //   position: "absolute",
                      //   height: "2px",
                      //   bottom: "0",
                      //   width: "100%",
                      //   transition:
                      //     "all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                      //   backgroundColor: "#1976d2",
                      // },
                    }}
                    label={head.label}
                    value={head.value}
                    
                  />
                ))}
                {buttons && (
                  <div className="tw-flex tw-items-center tw-px-4">
                    {buttons?.map((x) => (
                      <button
                        onClick={x.buttonFunction}
                        className={x.buttonStyles}
                        key={x.buttonName}
                      >
                        {x.buttonName}
                      </button>
                    ))}
                  </div>
                )}
              </TabList>
            </Box>
            {children}
          </TabContext>
        </div>
      </div>
    </>
  );
};

export default CommonTab;
