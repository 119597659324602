import axios from "axios";
import { ADMIN_BACKEND_URL } from "../../../../config/config";
const token = localStorage.getItem('adminToken')

  export function getStockRequest({payload}) {
    const fromDate = payload?.fromDate
    const toDate = payload?.toDate
    console.log(fromDate,'aluva');
    console.log(toDate,'perumbavoor');
    return axios.request({
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "get",
      url: `${ADMIN_BACKEND_URL}/admin/stock-loaded?from=${fromDate}&to=${toDate}`,
    });
  }