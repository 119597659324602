import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { lazy } from "react";
import { getProfile } from "./services/profileService";
import { setCountryCode } from "./redux/ducks/countrySlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// import Home from "./pages/home/Home";
import Loadable from "./components/LazyLaoding/Loadable";
import Profile from "./pages/profile/Profile";
import DeliveryPrefix from "./pages/Delivery/DeliveryPrefix";
import Estimates from "./pages/estimates/Estimates";

const AddStaff = Loadable(lazy(() => import("./pages/staff/AddStaff")));
const AllStaff = Loadable(lazy(() => import("./pages/staff/AllStaff")));
const EditStaff = Loadable(lazy(() => import("./pages/staff/EditStaff")));
const StaffDetails = Loadable(lazy(() => import("./pages/staff/StaffDetail")));
const Vehicles = Loadable(lazy(() => import("./pages/Vehicle/Vehicles")));
const AddVehicle = Loadable(lazy(() => import("./pages/Vehicle/AddVehicle")));
const EditVehicle = Loadable(lazy(() => import("./pages/Vehicle/EditVehicle")));
const ViewVehicle = Loadable(lazy(() => import("./pages/Vehicle/VehicleDetail")));


const Orders = Loadable(lazy(() => import("./pages/Order/Orders")));
const MainRoute = Loadable(lazy(() => import("./pages/Route/MainRoute")));
const AddMainRoute = Loadable(lazy(() => import("./pages/Route/AddMainRoute")));
const AddSubRoute = Loadable(lazy(() => import("./pages/Route/AddSubRoute")));
const EditMainRoute = Loadable(lazy(() => import("./pages/Route/EditMainRoute")));
const EditSubRoute = Loadable(lazy(() => import("./pages/Route/EditSubRoute")));
const SubRoute = Loadable(lazy(() => import("./pages/Route/SubRoute")));
const MainRouteDetail = Loadable(lazy(() => import("./pages/Route/MainRouteDetail")));
const StockLoaded = Loadable(lazy(() => import("./pages/Stock/StockLoeded")));
const InternalTransfers = Loadable(lazy(() => import("./pages/Stock/InternalTransfers")));
const AddInternalTransfers = Loadable(lazy(() => import("./pages/Stock/AddInternalTransfers")));
const EditInternalTransfers = Loadable(lazy(() => import("./pages/Stock/EditInternalTransfers")));


const AddStock = Loadable(lazy(() => import("./components/AddStock/AddStock")));
const EditStockPage = Loadable(lazy(() => import("./pages/Stock/EditStock")));
const AddStockPage = Loadable(lazy(() => import("./pages/Stock/AddStock")));
const ViewStockLoaded = Loadable(lazy(() => import("./pages/Stock/ViewStockLoaded")));
const SalesMans = Loadable(lazy(() => import("./pages/salesMan/SalesMan")));
const SalesList = Loadable(lazy(() => import("./pages/report/SalesList")));
const AddSalesMan = Loadable(lazy(() => import("./components/AddSalesMan/AddSalesMan")));
const Customers = Loadable(lazy(() => import("./pages/customer/Customer")));
const AddCustomers = Loadable(lazy(() => import("./pages/customer/AddCustomers")));
const EditCustomers = Loadable(lazy(() => import("./pages/customer/EditCustomers")));
const AddNewCustomer = Loadable(lazy(() => import("./components/AddNewCustomer/AddNewCustomer")));
const CustomerDashboard = Loadable(lazy(() => import("./components/CustomerDashboard/CustomerDashboard")));


const AllCustomers = Loadable(lazy(() => import("./pages/customer/Customer")));
const AddOrderPage = Loadable(lazy(() => import("./pages/Order/AddOrder")));
const OrderDetail = Loadable(lazy(() => import("./pages/Order/OrderDetail")));
const EditOrderPage = Loadable(lazy(() => import("./pages/Order/EditOrder")));
const CashSale = Loadable(lazy(() => import("./pages/report/CashSale")));
const CreditSale = Loadable(lazy(() => import("./pages/report/CreditSale")));
const ActiveRide = Loadable(lazy(() => import("./pages/Ride/Ride")));
const RideHistory = Loadable(lazy(() => import("./pages/Ride/RideHistory")));
const RideDashboard = Loadable(lazy(() => import("./components/RideDashboard/RideDashBoard")));
const DailyReport = Loadable(lazy(() => import("./components/DailyorRideReport/DailyorRideReport")));
const ContraEntry = Loadable(lazy(() => import("./pages/contraentry/ContraEntry")));



// warehose&&returnbottle
const W_Stock = Loadable(lazy(() => import("./pages/Warehouse/W_stock")));
const Bottles = Loadable(lazy(() => import("./pages/Warehouse/Bottles")));
const AddWStock = Loadable(lazy(() => import("./pages/Warehouse/AddW_Stock")));
const EditWStock = Loadable(lazy(() => import("./pages/Warehouse/EditW_Stock")));

// Coupon
const Coupon = Loadable(lazy(() => import("./pages/coupon/Coupon")));
const AddCoupon = Loadable(lazy(() => import("./pages/coupon/AddCoupon")));
const EditCoupon = Loadable(lazy(() => import("./pages/coupon/EditCoupon")));

// Credit Collection
const CreditCollection = Loadable(lazy(() => import("./pages/CreditCollection/CreditCollection")));
const AddCreditCollection = Loadable(lazy(() => import("./pages/CreditCollection/AddCreditCollection")));
const EditCreditCollection = Loadable(lazy(() => import("./pages/CreditCollection/EditCreditCollection")));

// Membership Customers
const MembershipCustomer = Loadable(lazy(() => import("./pages/MembershipCustomer/MembershipCustomer")));
const AddMemberShip = Loadable(lazy(() => import("./pages/MembershipCustomer/AddMemberShip")));
const EditMembership = Loadable(lazy(() => import("./pages/MembershipCustomer/EditMembership")));

const Expenses = Loadable(lazy(() => import("./pages/expenses/Expenses")));

// report
const ProfitAndLoss = Loadable(lazy(() => import("./pages/profitAndLoss/ProfitAndLoss")));
const BalanceSheet = Loadable(lazy(() => import("./pages/balanceSheet/BalanceSheet")));
const TrailBalance = Loadable(lazy(() => import("./pages/trailBalance/TrailBalance")));
const LedgerDashboard = Loadable(lazy(() => import('./components/ledgerDashboard/LedgerDashBoard')));
const TotalSale = Loadable(lazy(() => import("./pages/report/TotalSale")));

// Purchase and Debit Notes
const VendorCredits = Loadable(lazy(() => import('./pages/vendorCredits/VendorCredits')));
const AddAndEditVendorCredit = Loadable(lazy(() => import("./components/AddAndEditVendorCredit/AddAndEditVendorCredit")));
const DebitNoteDashboard = Loadable(lazy(() => import("./components/DebitNoteDashboard/DebitNoteDashboard")));

// Credit Notes
const CreditNotes = Loadable(lazy(() => import("./pages/creditNotes/CreditNotes")));
const CreditNoteDashboard = Loadable(lazy(() => import("./components/CreditNoteDashboard/CreditNoteDashboard")));
const AddAndEditCreditNote = Loadable(lazy(() => import("./components/AddAndEditCreditNote/AddAndEditCreditNote")));
const AddAndEditDebitNote = Loadable(lazy(() => import("./components/AddAndEditDebitNotes/AddAndEditDebitNotes")));

// Chart of Account
const AddAndEditChartOfAccount = Loadable(lazy(() => import("./components/AddAndEditChartOfAccount/AddAndEditChartOfAccount")));
const EditChartOfAccount = Loadable(lazy(() => import("./pages/chartOfAccounts/EditChartOfAccount")));

const AddAndEditNewJournal = Loadable(lazy(() => import('./components/AddNewJournal/AddAndEditJournal')));


const Items = Loadable(lazy(() => import("./pages/items/Items")));
const AddNewItem = Loadable(lazy(() => import("./components/AddNewItem/AddNewItem")));
const ItemDashboard = Loadable(lazy(() => import("./components/ItemDashboard/ItemDashboard")));
const Tax = Loadable(lazy(() => import("./pages/tax/Tax")));
const EstimateDashboard = Loadable(lazy(() => import("./components/EstimateDashboard/EstimateDashboard")));
const Invoices = Loadable(lazy(() => import("./pages/invoices/Invoices")));
const AddAndEditInvoice = Loadable(lazy(() => import("./components/AddAndEditInvoice/AddAndEditInvoice")));
const InvoiceDashboard = Loadable(lazy(() => import("./components/InvoiceDashboard/InvoiceDashboard")));
const AdminLogin = Loadable(lazy(() => import("./pages/adminLogin/AdminLogin")));
const SalesOrder = Loadable(lazy(() => import("./pages/salesOrder/SalesOrder")));
const AddAndEditSalesOrder = Loadable(lazy(() => import("./components/AddAndEditSalesOrder/AddAndEditSalesOrder")));
const SalesOrderDashboard = Loadable(lazy(() => import("./components/SalesOrderDashboard/SalesOrderDashboard")));
const Receipts = Loadable(lazy(() => import("./pages/receipts/Receipts")));
const AddAndEditPaymentReceipt = Loadable(lazy(() => import("./components/AddAndEditReceipt/AddAndEditPaymentReceipt")));
const AddAndEditRefundReceipt = Loadable(lazy(() => import("./components/AddAndEditReceipt/AddAndEditRefundReceipt")));
const RefundReceiptDashboard = Loadable(lazy(() => import("./components/ReceiptDashboard/RefundReceiptDashboard")));
const PaymentReceiptDashboard = Loadable(lazy(() => import("./components/ReceiptDashboard/PaymentReceiptDashboard")));
const AdminLoginRouter = Loadable(lazy(() => import("./utils/AdminLoginRouter")));
const QuoteDashboard = Loadable(lazy(() => import("./components/EstimateDashboard/QuoteDashboard")));
const Vendors = Loadable(lazy(() => import("./pages/vendors/Vendors")));
const PurchaseOrders = Loadable(lazy(() => import("./pages/purchaseOrder/PurchaseOrder")));
const PurchaseOrderDashboard = Loadable(lazy(() => import("./components/PurchaseOrderDashboard/PurchaseOrderDashboard")));
const VendorDashboard = Loadable(lazy(() => import("./components/VendorDashboard/VendorDashboard")));
const AddAndEditBill = Loadable(lazy(() => import("./components/AddAndEditBill/AddAndEditBill")));


const Bills = Loadable(lazy(() => import("./pages/bills/Bill")));
const AddAndEditGoodsReceiveNote = Loadable(lazy(() => import("./components/AddAndEditGoodsReceiveNote/AddAndEditGoodsReceiveNote")));
const GoodsReceiveNotes = Loadable(lazy(() => import("./pages/goodsReceiveNotes/goodsReceiveNotes")));
const BillDashboard = Loadable(lazy(() => import("./components/BillDashboard/BillDashboard")));
const GoodsReceiveNoteDashboard = Loadable(lazy(() => import("./components/GoodsReceiveNoteDashboard/GoodsReceiveNoteDashboard")));
const Banking = Loadable(lazy(() => import("./pages/banking/Banking")));
const AddAndEditExpense = Loadable(lazy(() => import("./components/AddAndEditExpense/AddAndEditExpense")));
const AddAndEditContraEntry = Loadable(lazy(() => import("./components/AddAndEditContraEntry/AddAndEditContraEntry")));
const JournalEntry = Loadable(lazy(() => import("./pages/journalEntry/JournalEntry")));
const AddNewJournal = Loadable(lazy(() => import("./components/AddNewJournal/AddNewJournal")));
const ChartOfAccounts = Loadable(lazy(() => import("./pages/chartOfAccounts/ChartOfAccounts")));
const AddAndEditVendor = Loadable(lazy(() => import("./components/AddAndEditVendor/AddAndEditVendor")));
const JournalEntryDashboard = Loadable(lazy(() => import("./components/JournalEntryDashboard/JournalEntryDashboard")));
const AddAndEditEstimate = Loadable(lazy(() => import("./components/AddAndEditEstimate/AddAndEditEstimate")));
const AddAndEditQuote = Loadable(lazy(() => import("./components/AddAndEditEstimate/AddAndEditQuote")));
const RootLayout = Loadable(lazy(() => import("./layouts/RootLayout")));
const PaymentReceipts = Loadable(lazy(() => import("./pages/paymentReceipts/PaymentReceipts")));
const AddAndEditPurchasePaymentReceipts = Loadable(lazy(() => import("./components/AddAndEditPurchasePaymentReceipts/AddAndEditPurchasePaymentReceipts")));
const AddAndEditPurchaseOrder = Loadable(lazy(() => import("./components/AddAndEditPurchaseOrder/AddAndEditPurchaseOrder")));
const PurchasePaymentReceiptDashboard = Loadable(lazy(() => import("./components/PurchaseReceiptDashboard/PruchasePaymentReceiptDashboard")));




const SalesManDr = Loadable(lazy(() => import("./pages/SalesManAccount/SalesManDr")));
const AddAndSalesManSCR = Loadable(lazy(() => import("./components/AddAndEditSalesManSCR/AddAndSalesManSCR")));
const CouponSale = Loadable(lazy(() => import("./pages/report/CouponSale")));
const ReturnStock = Loadable(lazy(() => import("./pages/report/ReturnStock")));
const SoldQuantity = Loadable(lazy(() => import("./pages/report/SoldQuantity")));

const AddAndEditUnloadFromRoute = Loadable(lazy(() => import("./components/AddAndEditUnloadFromRoute/AddAndEditUnloadFromRoute")));
const AddAndEditPurcahseReturn = Loadable(lazy(() => import("./components/AddAndEditPurchaseReturn/AddAndEditPurchaseReturn")));
const UnloadFromRoute = Loadable(lazy(() => import("./pages/unloadStock/UnloadFromRoute")));
const ViewUnloadStock = Loadable(lazy(() => import("./pages/unloadStock/ViewUnloadStockDetails")));
const PurchaseReturn = Loadable(lazy(() => import("./pages/purchaseReturn/PurchaseReturn")));
const InventoryDashboard = Loadable(lazy(() => import("./components/InventoryDashboard/InventoryDashboard")));
const DayBook = Loadable(lazy(() => import("./pages/DayBook/DayBook")));
const AddAndEditStockConversion = Loadable(lazy(() => import("./components/AddAndEditStockConversion/AddAndEditStockConversion")));
const Home = Loadable(lazy(() => import('./pages/home/Home')))

function App() {
  const { isLoggedIn } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchProfile = async () => {
      const { data } = await getProfile();
      localStorage.setItem("selectedCountryId", data.countryCode);
      dispatch(setCountryCode(data.countryCode));
    };
    fetchProfile();
  }, []);
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route element={<AdminLoginRouter />}>
            <Route
              path="/"
              element={!isLoggedIn ? <Navigate to="/login" /> : <RootLayout />}
            >
              <Route index element={<Home />} />

              <Route path="vehicle" element={<Vehicles></Vehicles>}></Route>
              <Route
                  path="sales/salesman-s.dr"
                  element={<SalesManDr />}
                />
                 <Route
                  path="sales/salesman-s.cr/add"
                  element={<AddAndSalesManSCR/>}
                />
              <Route
                path="add-vehicle"
                element={<AddVehicle></AddVehicle>}
              ></Route>

              <Route
                path="edit-vehicle/:id"
                element={<EditVehicle></EditVehicle>}
              ></Route>
              <Route
                path="view-vehicle/:id"
                element={<ViewVehicle></ViewVehicle>}
              ></Route>
              <Route path="orders" element={<Orders></Orders>}></Route>
              <Route
                path="add-order"
                element={<AddOrderPage></AddOrderPage>}
              ></Route>
              <Route
                path="edit-order/:id"
                element={<EditOrderPage></EditOrderPage>}
              ></Route>
              <Route
                path="view-order/:id"
                element={<OrderDetail></OrderDetail>}
              ></Route>

              <Route path="coupon" element={<Coupon></Coupon>}></Route>
              <Route path="daily" element={<DailyReport></DailyReport>}></Route>

              <Route
                path="add-coupon"
                element={<AddCoupon></AddCoupon>}
              ></Route>
              <Route
                path="edit-coupon/:id"
                element={<EditCoupon></EditCoupon>}
              ></Route>

              <Route path="customers/">
                <Route
                  path="all"
                  element={<AllCustomers></AllCustomers>}
                ></Route>
                <Route
                  path="add-customer"
                  element={<AddCustomers></AddCustomers>}
                ></Route>
                <Route
                  path="edit-customer/:id"
                  element={<EditCustomers></EditCustomers>}
                ></Route>
              </Route>

              <Route
                path="active-ride"
                element={<ActiveRide></ActiveRide>}
              ></Route>

              <Route
                path="ride-history"
                element={<RideHistory></RideHistory>}
              ></Route>
               <Route
                path="ride-dashboard/:id"
                element={<RideDashboard></RideDashboard>}
              ></Route>

              <Route path="route/">
                <Route
                  path="main-route"
                  element={<MainRoute></MainRoute>}
                ></Route>
                <Route
                  path="add-main-route"
                  element={<AddMainRoute></AddMainRoute>}
                ></Route>
                <Route
                  path="edit-main-route/:id"
                  element={<EditMainRoute></EditMainRoute>}
                ></Route>
                <Route
                  path="add-sub-route"
                  element={<AddSubRoute></AddSubRoute>}
                ></Route>
                <Route path="sub-route" element={<SubRoute></SubRoute>}></Route>
                <Route
                  path="edit-sub-route/:id"
                  element={<EditSubRoute></EditSubRoute>}
                ></Route>
                <Route
                  path="view-main-route/:id"
                  element={<MainRouteDetail></MainRouteDetail>}
                ></Route>
              </Route>
              <Route path="stock/">
                <Route
                  path="stock-loaded"
                  element={<StockLoaded></StockLoaded>}
                ></Route>
                <Route
                  path="add-stock"
                  element={<AddStockPage></AddStockPage>}
                ></Route>
                <Route
                  path="edit-stock/:id"
                  element={<EditStockPage></EditStockPage>}
                ></Route>
                <Route
                  path="add-internal-transfers"
                  element={<AddInternalTransfers></AddInternalTransfers>}
                ></Route>
                <Route
                  path="edit-internal-transfers/:id"
                  element={<EditInternalTransfers></EditInternalTransfers>}
                ></Route>
                <Route
                  path="internal-transfers"
                  element={<InternalTransfers></InternalTransfers>}
                ></Route>
                <Route
                  path="view-stock-loaded/:id"
                  element={<ViewStockLoaded></ViewStockLoaded>}
                ></Route>
                 <Route
                  path="add-unload"
                  element={<AddAndEditUnloadFromRoute></AddAndEditUnloadFromRoute>}
                ></Route>
                 <Route
                  path="unload"
                  element={<UnloadFromRoute />}
                ></Route>
                 <Route
                  path="view-unload/:id"
                  element={<ViewUnloadStock />}
                ></Route>
                <Route
                  path="purchase-return/add"
                  element={<AddAndEditPurcahseReturn></AddAndEditPurcahseReturn>}
                ></Route>
                 <Route
                  path="purchase-return"
                  element={<PurchaseReturn />}
                ></Route>
                <Route
                  path="inventory-dashboard/:id"
                  element={<InventoryDashboard />}
                ></Route>
                 <Route
                  path="stock-conversion"
                  element={<AddAndEditStockConversion />}
                ></Route>
              </Route>
              <Route path="sales/">
                <Route
                  path="membership"
                  element={<MembershipCustomer></MembershipCustomer>}
                ></Route>
                <Route
                  path="add-membership"
                  element={<AddMemberShip></AddMemberShip>}
                ></Route>
                <Route
                  path="edit-membership"
                  element={<EditMembership></EditMembership>}
                ></Route>
                <Route path="stock" element={<W_Stock />}></Route>
                <Route path="bottles" element={<Bottles></Bottles>}></Route>
                <Route path="add-w-stock" element={<AddWStock></AddWStock>} />
                <Route
                  path="edit-w-stock/:id"
                  element={<EditWStock></EditWStock>}
                />

                <Route path="customers" element={<Customers />} />
                <Route path="estimates" element={<Estimates />} />
                <Route
                  path="estimates/add-new-estimate"
                  element={<AddAndEditEstimate />}
                />

                <Route
                  path="estimates/add-new-quote"
                  element={<AddAndEditQuote />}
                />
                <Route path="estimates/:id" element={<EstimateDashboard />} />
                <Route
                  path="estimates/edit-estimate/:id"
                  element={<AddAndEditEstimate />}
                />
                <Route path="quotes/:id" element={<QuoteDashboard />} />
                <Route
                  path="estimates/edit-quote/:id"
                  element={<AddAndEditQuote />}
                />
                <Route path="invoices" element={<Invoices />} />
                <Route
                  path="invoices/add-new-invoice"
                  element={<AddAndEditInvoice />}
                />
                <Route path="invoices/:id" element={<InvoiceDashboard />} />
                <Route
                  path="invoices/edit-invoice/:id"
                  element={<AddAndEditInvoice />}
                />
                <Route path="sales-order" element={<SalesOrder />} />
                <Route
                  path="sales-order/add-new-sales-order"
                  element={<AddAndEditSalesOrder />}
                />
                <Route
                  path="sales-order/:id"
                  element={<SalesOrderDashboard />}
                />
                <Route
                  path="sales-order/edit-sales-order/:id"
                  element={<AddAndEditSalesOrder />}
                />
                <Route path="receipts" element={<Receipts />} />

                <Route
                  path="receipts/add-new-payment-receipt"
                  element={<AddAndEditPaymentReceipt />}
                />
                <Route
                  path="receipts/add-new-payment-receipt/:id"
                  element={<AddAndEditPaymentReceipt />}
                />
                <Route
                  path="receipts/add-new-refund-receipt"
                  element={<AddAndEditRefundReceipt />}
                />
                <Route
                  path="payment-receipts/:id"
                  element={<PaymentReceiptDashboard />}
                />
                <Route
                  path="refund-receipts/:id"
                  element={<RefundReceiptDashboard />}
                />
                <Route
                  path="receipts/edit-refund-receipt/:id"
                  element={<AddAndEditRefundReceipt />}
                />
                <Route path="items" element={<Items />} />
                <Route path="credit-notes">
                <Route index element={<CreditNotes />} />

                <Route
                  path="add-new-credit-note"
                  element={<AddAndEditCreditNote />}
                />
                <Route path=":id" element={<CreditNoteDashboard />} />
                <Route
                  path="edit-credit-note/:id"
                  element={<AddAndEditCreditNote />}
                />
                
              </Route>
              </Route>
              
              <Route path="report/">
                <Route
                  path="sales-list"
                  element={<SalesList></SalesList>}
                ></Route>
                 <Route
                  path="day-book"
                  element={<DayBook />}
                ></Route>
                <Route path="add-stock" element={<AddStock></AddStock>}></Route>
                <Route
                  path="add-internal-transfers"
                  element={<AddInternalTransfers></AddInternalTransfers>}
                ></Route>
                <Route
                  path="internal-transfers"
                  element={<InternalTransfers></InternalTransfers>}
                ></Route>
                <Route
                  path="credit-collection"
                  element={<CreditCollection></CreditCollection>}
                ></Route>
                <Route
                  path="add-credit-collection"
                  element={<AddCreditCollection></AddCreditCollection>}
                ></Route>
                <Route
                  path="edit-credit-collection"
                  element={<EditCreditCollection></EditCreditCollection>}
                ></Route>
                <Route path="profit-and-loss" element={<ProfitAndLoss />} />
                <Route path="balance-sheet" element={<BalanceSheet />} />
                <Route path="trail-balance" element={<TrailBalance />} />
                <Route path="ledger-dashboord/:id" element={<LedgerDashboard />} />


              </Route>

              <Route path="cash-sale/" element={<CashSale></CashSale>}></Route>
              <Route path="coupon-sale/" element={<CouponSale></CouponSale>}></Route>
              <Route path="return-stock" element={<ReturnStock></ReturnStock>}></Route>
              <Route path="stock-quantity" element={<SoldQuantity></SoldQuantity>}></Route>


              <Route
                path="total-sale/"
                element={<TotalSale></TotalSale>}
              ></Route>
              <Route
                path="credit-sale/"
                element={<CreditSale></CreditSale>}
              ></Route>


              <Route path="settings/">
                <Route path="profile" element={<Profile />} />
                <Route path="tax" element={<Tax />} />
                <Route path="delivery" element={<DeliveryPrefix />} />
              </Route>
              <Route path="add-staff" element={<AddStaff></AddStaff>}></Route>
              <Route
                path="edit-staff/:id"
                element={<EditStaff></EditStaff>}
              ></Route>

              <Route path="staff" element={<AllStaff></AllStaff>}></Route>
              <Route
                path="add-salesman"
                element={<AddSalesMan></AddSalesMan>}
              ></Route>
              <Route path="sales-man" element={<SalesMans></SalesMans>}></Route>

              <Route
                path="staff-details/:id"
                element={<StaffDetails></StaffDetails>}
              />

              <Route path="addcustomer" element={<AddNewCustomer />} />
              <Route path="addcustomer/:id" element={<AddNewCustomer />} />
              <Route
                path="customerdashboard/:id"
                element={<CustomerDashboard />}
              />
              <Route path="additem" element={<AddNewItem />} />
              <Route path="edititem/:id" element={<AddNewItem />} />
              <Route path="itemdashboard/:id" element={<ItemDashboard />} />

              <Route path="purchases/">
                <Route path="vendors" element={<Vendors />} />
                <Route
                  path="vendors/add-new-vendor"
                  element={<AddAndEditVendor />}
                />
                <Route path="vendors/:id" element={<VendorDashboard />} />
                <Route
                  path="vendors/edit-vendor/:id"
                  element={<AddAndEditVendor />}
                />
                <Route path="purchase-orders" element={<PurchaseOrders />} />

                <Route
                  path="purchase-orders/add-new-purchase-order"
                  element={<AddAndEditPurchaseOrder />}
                />
                <Route path="bills/add-new-bill" element={<AddAndEditBill />} />
                <Route path="payment-receipts" element={<PaymentReceipts />} />
                <Route
                  path="payment-receipts/add-new-payment-receipt"
                  element={<AddAndEditPurchasePaymentReceipts />}
                />
                <Route
                  path="payment-receipts/add-new-payment-receipt/:id"
                  element={<AddAndEditPurchasePaymentReceipts />}
                />
                <Route
                path="payment-receipts/:id"
                element={<PurchasePaymentReceiptDashboard />}
              />
                <Route
                  path="goods-receive-notes/add-new-goods-receive-note"
                  element={<AddAndEditGoodsReceiveNote />}
                />
                <Route
                  path="purchase-orders/:id"
                  element={<PurchaseOrderDashboard />}
                />
                <Route
                  path="purchase-orders/edit-purchase-order/:id"
                  element={<AddAndEditPurchaseOrder />}
                />
                <Route path="bills" element={<Bills />} />
                <Route path="bills/:id" element={<BillDashboard />} />
                <Route
                  path="bills/edit-bill/:id"
                  element={<AddAndEditBill />}
                />
                <Route
                  path="goods-receive-notes"
                  element={<GoodsReceiveNotes />}
                />
                <Route
                  path="goods-receive-notes/:id"
                  element={<GoodsReceiveNoteDashboard />}
                />
                <Route
                  path="goods-receive-notes/edit-goods-receive-note/:id"
                  element={<AddAndEditGoodsReceiveNote />}
                />
                <Route path="expenses">
                  <Route index element={<Expenses />} />
                  <Route
                    path="add-new-petty-cash-expense"
                    element={<AddAndEditExpense />}
                  />
                  <Route
                    path="add-new-bank-expense/:id"
                    element={<AddAndEditExpense />}
                  />
                  <Route
                    path="edit-expense/:id"
                    element={<AddAndEditExpense />}
                  />
                  {/* <Route path=":id" element={<ExpenseDashboard />} /> */}
                </Route>
                <Route path="debit-notes">
                  <Route index element={<VendorCredits />} />

                  <Route
                    path="add-new-debit-note"
                    element={<AddAndEditDebitNote />}
                  />
                  <Route path=":id" element={<DebitNoteDashboard />} />
                  <Route
                    path="edit-debit-notes/:id"
                    element={<AddAndEditVendorCredit />}
                  />
                </Route>
              </Route>

              <Route path="accounting/">
                <Route path="banking" element={<Banking />} />
                <Route
                  path="banking/add-new-petty-cash-expense"
                  element={<AddAndEditExpense />}
                />
                <Route
                  path="banking/add-new-bank-expense/:id"
                  element={<AddAndEditExpense />}
                />
                 <Route
                  path="contra-entry"
                  element={<ContraEntry />}
                />
                <Route
                  path="banking/edit-expense/:id"
                  element={<AddAndEditExpense />}
                />
                <Route
                  path="banking/add-new-contra-entry"
                  element={<AddAndEditContraEntry />}
                />
                <Route
                  path="banking/edit-contra-entry/:id"
                  element={<AddAndEditContraEntry />}
                />
                <Route path="journalEntry" element={<JournalEntry />} />
                {/* <Route path="add-new-journal" element={<AddNewJournal />} /> */}
                <Route path="add-new-journal" element={<AddAndEditNewJournal />} />

                <Route path="edit-journal/:id" element={<AddAndEditNewJournal />} />
                <Route path="chart-of-accounts" element={<ChartOfAccounts />} />
                <Route path="chart-of-accounts/add" element={<AddAndEditChartOfAccount />}/>
                <Route path="chart-of-accounts/edit/:id" element={<EditChartOfAccount />}/>


              </Route>
              <Route
                path="jounral-entry-dashboard/:id"
                element={<JournalEntryDashboard />}
              />
            </Route>
            <Route
              path="login"
              element={isLoggedIn ? <Navigate to="/" /> : <AdminLogin />}
            />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
